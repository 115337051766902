import React, { node } from 'react'

import {
  UikDivider,
  UikWidget,
  UikWidgetHeader,
  UikWidgetBottomCta,
} from 'uikit/components'

const UikForm = ({ bottomCta, children, className, header, ...rest }) => (
  <UikWidget Component="form" {...rest} className={className}>
    {header && <UikWidgetHeader>{header}</UikWidgetHeader>}
    {children}
    {bottomCta && (
      <div>
        <UikDivider />
        <UikWidgetBottomCta {...bottomCta}>
          {bottomCta.title}
        </UikWidgetBottomCta>
      </div>
    )}
  </UikWidget>
)

UikForm.defaultProps = {
  bottomCta: undefined,
  className: undefined,
  header: undefined,
}

export default UikForm

import * as React from 'react'
import classnames from 'classnames'

import cls from './top-bar.module.scss'

const Header = ({ children, className, center, ...rest }) => (
  <div
    className={classnames(cls.wrapper, className, {
      [cls.center]: center,
    })}
    {...rest}
  >
    {children}
  </div>
)

Header.defaultProps = {
  className: null,
  children: null,
  center: false,
}

export default Header

import React, { Component } from 'react'

export default (loader, collection) =>
  class AsyncComponent extends Component {
    constructor(props) {
      super(props)
      this.Component = null
      this.state = { Component: AsyncComponent.Component }
    }
    componentWillMount() {
      if (!this.state.Component) {
        loader().then((Comp) => {
          AsyncComponent.Component = Comp
          this.setState({ Component: Comp })
        })
      }
    }

    render() {
      if (this.state.Component) {
        return <this.state.Component {...this.props} {...collection} />
      }
      return <div className={collection.className} />
    }
  }

import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { StoreContext } from 'contexts/Store'
import Login from 'containers/Login'
import RecoverPassword from 'containers/RecoverPassword'
import ResetPassword from 'containers/ResetPassword'

import { Modal, Header, Body, Close } from '@zendeskgarden/react-modals'
// import '@zendeskgarden/react-modals/dist/styles.css';

import cls from './loginModal.module.scss'
import clsApp from 'initStyles/App.scss'

const LoginModal = ({ closeModal }) => {
  const {
    loginModal: { mode, setCurrentState },
  } = useContext(StoreContext)

  return (
    <Modal
      className={cls.responsiveModal}
      //className="responsiveModal"
      backdropProps={{
        onClick: (e) => {
          e.stopPropagation()
          e.preventDefault()
        },
      }}
      onClose={(e) => {
        closeModal()
        e.stopPropagation()
      }}
    >
      {mode === 'login' ? (
        <div>
          <Header>
            <span role="img" aria-label="Hello">
              👋
            </span>{' '}
            Heureux de vous revoir
          </Header>
          <Body style={{ padding: '20px 40px 20px' }}>
            <Login
              emailForgotten={() => {
                setCurrentState('recover')
              }}
              clickOnSignUp={closeModal}
            />
          </Body>
        </div>
      ) : mode === 'recover' ? (
        <div>
          <Header>Récupérer votre mot de passe</Header>
          <Body style={{ padding: '20px 40px 20px' }}>
            <RecoverPassword
              back={() => {
                setCurrentState('login')
              }}
              clickOnClose={closeModal}
            />
            <button
              className={clsApp.textButton}
              onClick={() => {
                setCurrentState('login')
              }}
              style={{ fontSize: '1rem', marginTop: '16px' }}
            >
              ← retour
            </button>
          </Body>
        </div>
      ) : (
        <div>
          <Header>Changer de mot de passe</Header>
          <Body style={{ padding: '20px 40px 20px' }}>
            <ResetPassword
              back={() => {
                setCurrentState('login')
              }}
              clickOnClose={closeModal}
              setCurrentState={setCurrentState}
            />
            <button
              className={clsApp.textButton}
              onClick={() => setCurrentState('login')}
              style={{ fontSize: '1rem', marginTop: '16px' }}
            >
              ← retour
            </button>
          </Body>
        </div>
      )}
      {mode !== 'login' ? (
        <Close
          onClick={() => setCurrentState('login')}
          aria-label="Close modal"
        />
      ) : (
        <Close
          onClick={() => window.location.replace('/')}
          aria-label="Close modal"
        />
      )}
    </Modal>
  )
}

LoginModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default LoginModal

import React, { useState, useEffect, useRef } from "react";
import { gql, useMutation } from "@apollo/client";
import UIAvatar from 'react-ui-avatars';
import { UikAvatar, UikSvg } from 'uikit/components';
import { SVG_URI } from '../../utils/vars';
import styles from './chatbot.module.scss';

const CHAT_GPT_THREAD = gql`
  mutation ChatGptThread($prompt: String!, $threadId: String) {
    chatGptThread(prompt: $prompt, threadId: $threadId)
  }
`;

const Chatbot = (props) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [threadId, setThreadId] = useState(null);
  const [tagSuggestion, setTagSuggestion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [showIntroMessage, setShowIntroMessage] = useState(true);

  const recognitionRef = useRef(null);
  const finalTranscriptRef = useRef("");
  const [errorMessage, setErrorMessage] = useState(""); // For displaying errors

  const messagesEndRef = useRef(null); // Reference to the end of the chat
  const chatContainerRef = useRef(null); // Reference to the chat container

  const [chatGptThread] = useMutation(CHAT_GPT_THREAD);
  const profile = props.profile;

  const handleChange = (e) => {
    setInput(e.target.value);

    // Automatically adjust the height of the textarea based on content
    const textarea = e.target;
    textarea.style.height = "auto"; // Reset height to auto
    textarea.style.height = `${textarea.scrollHeight}px`; // Adjust height based on content

    if (!isListening) {
      finalTranscriptRef.current = e.target.value;
    }
  };

  useEffect(() => {
    if (isListening) {
      recognitionRef.current = new window.webkitSpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;
      recognitionRef.current.lang = "fr-FR";

      recognitionRef.current.onresult = (event) => {
        let interimTranscript = "";

        for (let i = event.resultIndex; i < event.results.length; i++) {
          if (event.results[i].isFinal) {
            if (finalTranscriptRef.current && finalTranscriptRef.current.slice(-1) !== ' ') {
              finalTranscriptRef.current += " ";
            }
            finalTranscriptRef.current += event.results[i][0].transcript;
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }

        setInput(finalTranscriptRef.current + interimTranscript);
      };

      recognitionRef.current.onerror = (event) => {
        console.error("Erreur de reconnaissance vocale:", event.error);
        setIsListening(false);
      };

      recognitionRef.current.onend = () => {
        if (isListening && recognitionRef.current) {
          recognitionRef.current.start();
        }
      };

      recognitionRef.current.start();
    } else {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
        recognitionRef.current = null;
      }
    }

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
    };
  }, [isListening]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom(); // Automatically scroll to the bottom when new messages are added
  }, [messages]);

  const sendMessage = () => {
    if (!input.trim()) return;

    const userMessage = { role: "user", content: input };

    if (showIntroMessage) {
      setShowIntroMessage(false);
    }

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setLoading(true);
    setErrorMessage(""); // Clear previous errors

    chatGptThread({
      variables: { prompt: input, threadId: threadId },
    })
      .then(({ data }) => {
        const response = JSON.parse(data.chatGptThread);
        const assistantMessage = {
          role: "assistant",
          content: response.response,
        };
        console.log("response", response.tags);
        setMessages((prevMessages) => [...prevMessages, assistantMessage]);
        setThreadId(response.threadId);
        //setTagSuggestion(response.tags);
      })
      .catch((error) => {
        setErrorMessage(`Ooops, nous avons perdu le contact. Renvoie moi ton dernier message ou contacte-nous à tech@whire.me si le problème persiste.`); // Display error message
        console.log("Error sending message:", error.networkError);
        console.log("Error sending message 123:", error.graphQLErrors);
      })
      .finally(() => {
        setLoading(false);
        setInput("");
        finalTranscriptRef.current = "";
        const textarea = document.querySelector(`.${styles.chatbotInput}`);
        if (textarea) {
          textarea.style.height = "2.6em"; // Reset the height of the input field
        }
      });
  };

  const getRandomColor = (letter) => {
    let colorValues = '#FFC117';
    if (letter.match(/^[a-cA-C 0-9\.\,\+\-]*$/)) {
      return colorValues;
    } else if (letter.match(/^[d-fD-F\.\,\+\-]*$/)) {
      return '#007ED9';
    } else if (letter.match(/^[g-iG-I\.\,\+\-]*$/)) {
      return '#ED4A5E';
    } else if (letter.match(/^[j-lJ-L\.\,\+\-]*$/)) {
      return '#00A2AE';
    } else if (letter.match(/^[m-oM-O\.\,\+\-]*$/)) {
      return '#3C26BB';
    } else if (letter.match(/^[p-rP-R\.\,\+\-]*$/)) {
      return '#666be2';
    } else if (letter.match(/^[s-uS-U\.\,\+\-]*$/)) {
      return '#ff015b';
    } else if (letter.match(/^[v-xV-X\.\,\+\-]*$/)) {
      return '#30ca93';
    } else if (letter.match(/^[y-zY-Z\.\,\+\-]*$/)) {
      return '#ffd000';
    } else {
      return '#F56A00';
    }
  };

  // Detect if the user is on Chrome or Safari
  const isChromeOrSafari = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /chrome|safari/.test(userAgent) && !/edg|opr|firefox/.test(userAgent);
  };

  const speakMessage = (text) => {
    if ('speechSynthesis' in window) {
      console.log("Synthèse vocale prise en charge par ce navigateur.");
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = "fr-FR"; // Définit la langue en français
      window.speechSynthesis.speak(utterance);
    } else {
      console.log("Synthèse vocale non prise en charge par ce navigateur.");
    }
  };
  
  

  return (
    <div>
      <div className={styles.chatbotHeader}>
        Exprimer sa motivation
      </div>
      <div className={styles.chatbotContainer} ref={chatContainerRef}>
        <div className={styles.chatbotMessages}>
          {showIntroMessage && (
            <div className={styles.introMessage}>
              <p>Salut à toi 👋</p>
              <p>Prêt/e à donner un coup de boost à ta page Whire ? Voici comment ça marche :</p>
              <ol>
                <li><span>Commence par dire bonjour</span> à notre chatbot sympa, et laisse-le te poser quelques questions clés. L’idée, c’est de creuser ensemble les <span>origines de ton projet</span> et ce qui te motive vraiment.</li>
                <li><span>Réponds aux questions</span> du chatbot librement comme si tu parlais à un/e ami/e et laisse-le te guider. Promis, ce n’est pas un interrogatoire ! Le but, c’est de t’aider à clarifier ta vision, ton "pourquoi", et ce qui rend ton projet unique.</li>
                <li>À la fin, le chatbot va te proposer une <span>synthèse personnalisée</span>. C'est une base solide que tu pourras utiliser pour remplir les différentes sections de ta page Whire.</li>
                <li><span>Tu veux modifier la synthèse ?</span> Pas de souci ! Demande au chatbot de faire les ajustements que tu souhaites jusqu'à ce que tu sois satisfait(e).</li>
                <li>Quand tout est bon, <span>copie-colle</span> le texte dans ta page Whire. À toi de répartir les infos dans les rubriques qui te semblent les plus logiques et cohérentes.</li>
              </ol>
              <p>💡 <span>Astuce 1</span> : Si tu es plus à l’aise dans une autre langue, pas de panique ! Parle au chatbot dans la langue de ton choix et vois s'il te suit.</p>
              <p>💡 <span>Astuce 2</span> : Tu peux <span>écrire tes réponses</span> ou, si tu préfères parler, utiliser la fonction dictée en appuyant sur le bouton micro (Chrome et Safari uniquement). Parle, et stoppe quand tu as fini ta phrase. Facile, non ?</p> 
              <p>Simple, rapide et efficace, tu vas adorer 😎</p>
            </div>
          )}
          {messages.map((message, index) => (
            <div
              key={index}
              className={`${styles.message} ${
                message.role === "user"
                  ? styles.userMessage
                  : styles.assistantMessage
              }`}
            >
              {message.role === "user" ? (
                <div className={styles.avatar}>
                  {profile && profile.image ? (
                    <UikAvatar
                      imageId={profile.image}
                      imageModifier={profile.imageModifier}
                      size="small"
                      style={{ justifyContent: 'space-around' }}
                    />
                  ) : (
                    <UIAvatar
                      background={getRandomColor(profile?.title?.charAt(0).toLowerCase() || 'w')}
                      name={profile?.title || 'W'}
                      color="#FFFFFF"
                      rounded
                      size="28px"
                    />
                  )}
                </div>
              ) : (
                <UikSvg
                  src={`${SVG_URI}/Logo-RGB.svg`}
                  alt="Logo Whire"
                  width="20px"
                  height="20px"
                  className={styles.chatbotIcon}
                />
              )}
              <pre className={styles.messageContent}>
                {message.content}
                {/* {message.role === "assistant" && tagSuggestion && (
                  <pre className={styles.messageContent}>Voici une suggestion de tag : {tagSuggestion}</pre>
                )} */}
              </pre>
              {/* {message.role === "assistant" && (
                <button onClick={() => speakMessage(message.content)} className={styles.voiceButton}>
                  🎤 Écouter
                </button>
              )} */}
            </div>
          ))}
          {loading && (
            <div className={`${styles.message} ${styles.loadingMessage}`}>
              🤖  Chargement de la réponse...
            </div>
          )}
          {errorMessage && (
            <div className={styles.errorMessage}>
              {errorMessage}
            </div>
          )}
          <div ref={messagesEndRef} /> {/* Reference to scroll automatically to the bottom */}
        </div>
        <div className={styles.chatbotInputContainer}>
          <textarea
            className={styles.chatbotInput}
            value={input}
            onChange={handleChange}
            onKeyDown={(e) => e.key === "Enter" && !e.shiftKey && sendMessage()}
            placeholder="Discute avec ton Whirrior personnel de l’accompagnement"
            disabled={loading}
            rows="1"
          />
          <UikSvg
            alt="Envoyer"
            height="42px"
            src={`${SVG_URI}/Icon feather-send.svg`}
            width="42px"
            onClick={sendMessage}
            disabled={loading}
            className={styles.chatbotButton}
          />
          {isChromeOrSafari() && (
            <UikSvg
              alt="Microphone"
              height="18px"
              src={isListening ? `${SVG_URI}/Icon feather-pause-circle.svg` : `${SVG_URI}/Icon awesome-microphone.svg`}
              width="26px"
              onClick={() => setIsListening(!isListening)}
              disabled={loading}
              className={styles.chatbotVoiceButton}
            />
          )}
        </div>
        <p className={styles.chatbotConfidential}>
          Notre chatbot peut faire des erreurs. Envisager de vérifier les informations générées. <a href="/privacy-ai" target="_blank">Politique de confidentialité</a>
        </p>
      </div>
    </div>
  );
};

export default Chatbot;

import * as React from 'react'
import classnames from 'classnames'

import cls from './top-bar-link.module.scss'

const UikTopBarLink = ({ children, className, Component, ...rest }) => (
  <Component className={classnames(cls.wrapper, className)} {...rest}>
    {children}
  </Component>
)

UikTopBarLink.defaultProps = {
  className: null,
  Component: 'a',
}

export default UikTopBarLink

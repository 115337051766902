import * as React from 'react'
import classnames from 'classnames'

import cls from './container-h.module.scss'

const UikContainerHorizontal = ({
  className,
  children,
  Component,
  ...rest
}) => (
  <Component className={classnames(cls.wrapper, className)} {...rest}>
    {children}
  </Component>
)

UikContainerHorizontal.defaultProps = {
  className: null,
  Component: 'div',
  children: null,
}

export default UikContainerHorizontal

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".D9g62zCimMHRhszdxkVU{flex-grow:1;height:100%;display:flex}.D9g62zCimMHRhszdxkVU>*{min-width:1px}", "",{"version":3,"sources":["webpack://./src/uikit/containers/UikContainerHorizontal/container-h.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,WAAA,CACA,YAAA,CACA,wBACE,aAAA","sourcesContent":[".wrapper {\n  flex-grow: 1;\n  height: 100%;\n  display: flex;\n  & > * {\n    min-width: 1px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "D9g62zCimMHRhszdxkVU"
};
export default ___CSS_LOADER_EXPORT___;

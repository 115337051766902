import * as React from 'react'
import classnames from 'classnames'
import cls from './loader-dots.module.scss'

const LoaderDots = ({ className, isWhite }) => (
  <div
    className={classnames(
      isWhite ? cls.dotloaderOne : cls.dotloaderTwo,
      className,
      'btn-loader'
    )}
  >
    <div className={cls.dotloader1} />
    <div className={cls.dotloader2} />
    <div className={cls.dotloader3} />
  </div>
)

LoaderDots.defaultProps = {
  className: null,
  isWhite: true,
}

export default LoaderDots

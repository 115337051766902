// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".w2pumDIOUBYUtKROyTbO,.w2pumDIOUBYUtKROyTbO:focus{border:none;border-radius:0;font-size:.875rem;min-width:100%;min-height:38px;text-align:left}.w2pumDIOUBYUtKROyTbO:not(:last-child),.w2pumDIOUBYUtKROyTbO:focus:not(:last-child){border-bottom:1px solid #eaedf3}", "",{"version":3,"sources":["webpack://./src/uikit/components/UikDropdownItem/dropdown-item.module.scss"],"names":[],"mappings":"AAEA,kDACE,WAAA,CACA,eAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,oFACE,+BAAA","sourcesContent":["@import \"src/initStyles/_vars.scss\";\n\n.wrapper, .wrapper:focus {\n  border: none;\n  border-radius: 0;\n  font-size: 0.875rem;\n  min-width: 100%;\n  min-height: 38px;\n  text-align: left;\n  &:not(:last-child) {\n    border-bottom: 1px solid $borderColor;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "w2pumDIOUBYUtKROyTbO"
};
export default ___CSS_LOADER_EXPORT___;

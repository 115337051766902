import {
  DOMAIN_NETLIFY,
  ALGOLIA_API,
  UPLOADCARE_PUBLIC,
  UPLOADCARE_PRIVATE,
  CONTACT_ME_SERVER,
} from '../../kit/project'

/* Related to draft-js entities, matching Algolia type documents */
export const USER_ENTITY_TYPE = 'USER'
export const TAG_ENTITY_TYPE = 'TAG'

export const SVG_URI = `${window.location.origin}/static/svg`
export const IMG_URI = `${window.location.origin}/static`

export const USER_STORY_TYPE = [
  // 'WHAT_I_AM',
  'WORK',
  'VOLUNTEER',
  'EDUCATION',
  'PUBLICATIONS',
  'PROJECT',
  'MEETING',
  'INTERESTS',
  'AWARDS',
  'FAIL',
  'LANGUAGES',
  'SKILLS',
  'DRAFT',
  'IFRAME',
  'RECEPTION',
  'MORE',
]

export const COMPANY_STORY_TYPE_ITERABLE = {
  PAGE: {
    hideInStoriesMenu: true,
    public: true,
    force: true,
    text: 'Présentation',
    appends: '',
  },
  WELCOME: {
    public: true,
    text: 'Vous voulez accueillir nos candidats',
    appends: 'accueil',
    iconStoryMenu: 'team',
    color: '#00d1b2',
    description:
      "Vous pouvez présenter ici les différentes possibilités qu'ont les employeurs souhaitant accueillir les personnes que vous accompagnez et en préciser les modalités",
    form: {
      header: "👇 Ajouter une modalité d'accueil de vos candidats",
      hasEndDate: false,
      hasStartDate: false,
      endDateCanBeNow: false,
      titleLabel: "Libellé de votre modalité d'accueil",
      startDateLabel: '',
      titlePlaceHolder: '',
      endDateLabel: '',
      endDateNowLabel: '',
    },
  },
  MEETING: {
    public: true,
    text: 'Nos événements',
    appends: 'evenements',
    iconStoryMenu: 'contacts',
    color: '#6d69df',
    description:
      'Vous organisez des événements auxquels les employeurs sont invités à se joindre ou à contribuer par exemple (job dating, sessions de présentation de métiers...) ? Parlez-en ici',
    form: {
      header: '👇 Nouvel évènement',
      hasEndDate: true,
      hasStartDate: true,
      endDateCanBeNow: true,
      titleLabel: 'Nom de votre évènement',
      startDateLabel: "(Optionnel) Début de l'histoire",
      titlePlaceHolder: '',
      endDateLabel: "(Optionnel) Fin de l'histoire",
      endDateNowLabel: 'Cette histoire est toujours en cours',
    },
  },
  PUBLICATION: {
    public: true,
    text: 'Publications / Articles',
    appends: 'articles',
    iconStoryMenu: 'book',
    color: 'rgb(0, 162, 174)',
    description:
      'Les publications ou les articles sont un excellent moyen de mettre en avant le savoir-faire de votre structure et de vos bénéficiaires.',
    form: {
      header: '👇 Ajouter un article / publication',
      hasEndDate: true,
      hasStartDate: true,
      endDateCanBeNow: true,
      titleLabel: 'Titre de votre article / publication',
      startDateLabel: '(Optionnel) Date de parution',
      titlePlaceHolder: '',
      endDateLabel: '',
      endDateNowLabel: '',
    },
  },
  SUCCESS: {
    public: true,
    text: 'Le coin des belles histoires (success stories)',
    appends: 'success',
    iconStoryMenu: 'heart',
    color: 'rgb(245, 106, 0)',
    description:
      "Rien de tel que de belles histoires pour rassurer un employeur et lui donner envie de rencontrer vos candidats. Racontez-les ici et incluez des témoignages, l'avis d'employeurs déjà convaincus si possible",
    form: {
      header: '👇 Ajouter une belle histoire',
      hasEndDate: false,
      hasStartDate: true,
      endDateCanBeNow: false,
      titleLabel: 'Titre de votre belle histoire',
      startDateLabel: '(Optionnel) Date de la belle histoire',
      titlePlaceHolder: '',
      endDateLabel: '',
      endDateNowLabel: '',
    },
  },
  WHOAMI: {
    public: true,
    text: 'Qui se cache derrière cette page ?',
    appends: 'whoami',
    iconStoryMenu: 'user',
    color: '#94aab0',
    description:
      "Authenticité, ce n'est pas juste un mot chez Whire, mais cela se traduit en acte ;) Nous vous proposons d'incarner votre page et de l'humaniser pour de vrai ! Soit en créant votre page Whire personnelle pour vous présenter et en partageant le lien dans cette rubrique, soit tout simplement en créant une story pour dire quelques mots sur vous. A vous de jouer ;)",
    form: {
      header: "👇 Ajouter la présentation de l'un de nous",
      hasEndDate: false,
      hasStartDate: false,
      endDateCanBeNow: false,
      titleLabel: "Je m'appelle",
      startDateLabel: '',
      titlePlaceHolder: '',
      endDateLabel: '',
      endDateNowLabel: '',
    },
  },
  FREESTORY: {
    public: true,
    text: "Plus d'informations",
    appends: 'freestory',
    iconStoryMenu: 'plus-circle',
    color: '#40A9FF',
    description:
      'Toutes les informations complémentaires que vous souhaitez donner à un employeur',
    form: {
      header: '👇 Ajouter une information complémentaire',
      hasEndDate: true,
      hasStartDate: true,
      endDateCanBeNow: true,
      titleLabel: 'Titre',
      startDateLabel: "(Optionnel) Début de l'histoire",
      titlePlaceHolder: '',
      endDateLabel: "(Optionnel) Fin de l'histoire",
      endDateNowLabel: 'Cette histoire est toujours en cours',
    },
  },

  // PROJECT: {
  //   public: true,
  //   text: 'Projets',
  //   appends: 'projets',
  //   iconStoryMenu: 'tool',
  //   color: "rgb(60, 38, 187)",
  //   description: "Personnels comme professionnels, les projets sont le meilleur moyen de se faire reconnaître. Vous pouvez les lister ici. ",
  //   form: {
  //     header: '👇 Ajouter un projet',
  //     hasEndDate: true,
  //     hasStartDate: true,
  //     endDateCanBeNow: true,
  //     titleLabel: 'Votre projet',
  //     startDateLabel: 'Date de démarrage',
  //     titlePlaceHolder: '',
  //     endDateLabel: 'Date de fin',
  //     endDateNowLabel: 'Projet en cours',
  //   },
  // },
  // AWARD: {
  //   public: true,
  //   text: 'Récompenses',
  //   appends: 'recompenses',
  //   iconStoryMenu: 'trophy',
  //   color: "#D73964",
  //   description: "Si votre organisation a remporté des prix ou des récompenses, vous pouvez les mentionner ici.",
  //   form: {
  //     header: '👇 Nouvelle récompense',
  //     hasEndDate: true,
  //     hasStartDate: true,
  //     endDateCanBeNow: true,
  //     titleLabel: 'Nom de la récompense',
  //     startDateLabel: 'Date de la récompense',
  //     titlePlaceHolder: '',
  //     endDateLabel: '',
  //     endDateNowLabel: '',
  //   },
  // },
  // INTEREST: {
  //   public: true,
  //   text: 'Centres d\'intérêt de l\'équipe',
  //   appends: 'centres-dinteret',
  //   iconStoryMenu: 'heart',
  //   color: "rgb(245, 106, 0)",
  //   description: "Vous pouvez lister ici les centres d'intérêts de l'organisation. Si possible, en ajoutant des images, liens ou vidéos.",
  //   form: {
  //     header: '👇 Ajouter un centre d\'intérêt',
  //     hasEndDate: true,
  //     hasStartDate: true,
  //     endDateCanBeNow: true,
  //     titleLabel: '',
  //     startDateLabel: '',
  //     titlePlaceHolder: '',
  //     endDateLabel: '',
  //     endDateNowLabel: '',
  //   },
  // },
}

export const COMPANY_STORY_TYPE_ARRAY = Object.entries(
  COMPANY_STORY_TYPE_ITERABLE
).map(([key, value]) => ({
  name: key,
  ...value,
}))

export const USER_STORY_TYPE_ITERABLE = {
  PAGE: {
    hideInStoriesMenu: true,
    public: true,
    force: true,
    text: 'Présentation',
    appends: '',
  },
  WORK: {
    public: true,
    text: 'Expériences pro',
    appends: 'experiences-pro',
    iconStoryMenu: 'profile',
    description:
      "J'indique mes expériences significatives et j'explique comment elles soutiennent mon projet professionnel actuel.",
    color: '#00d1b2',
    form: {
      header: '👇 Nouvelle histoire pro',
      hasEndDate: true,
      hasStartDate: true,
      endDateCanBeNow: true,
      titleLabel: "Titre de l'histoire",
      startDateLabel: "(Optionnel) Début de l'histoire",
      titlePlaceHolder: 'Développeur chez Whire',
      endDateLabel: "(Optionnel) Fin de l'histoire",
      endDateNowLabel: "Je suis encore dans l'histoire",
    },
  },
  VOLUNTEER: {
    public: true,
    text: 'Bénévolat',
    appends: 'benevolat',
    iconStoryMenu: 'like',
    color: 'rgb(255, 1, 91)',
    description:
      "Si j'ai mené des actions bénévoles, je peux les lister, expliquer en quelques mots mes activités ou mon engagement et comment elles m’ont fait évoluer en termes de compétences, de qualités mais aussi de valeurs...",
    form: {
      header: '👇 Nouvelle histoire de bénévolat',
      hasEndDate: true,
      hasStartDate: true,
      endDateCanBeNow: true,
      titleLabel: "Titre de l'histoire",
      startDateLabel: "(Optionnel) Début de l'histoire",
      titlePlaceHolder: 'Mentorat chez Amnesty International',
      endDateLabel: "(Optionnel) Fin de l'histoire",
      endDateNowLabel: "Je suis encore dans l'histoire",
    },
  },
  EDUCATION: {
    public: true,
    text: 'Formation',
    appends: 'cursus',
    iconStoryMenu: 'tags',
    color: 'rgb(255, 208, 0)',
    description:
      "Si j'ai suivi des cours ou des formations, je peux les ajouter, tagger les compétences acquises et raconter ce que j’en ai véritablement retiré.",
    form: {
      header: '👇 Nouvelle histoire de formation',
      hasEndDate: true,
      hasStartDate: true,
      endDateCanBeNow: true,
      titleLabel: "Titre de l'histoire",
      startDateLabel: "(Optionnel) Début de l'histoire",
      titlePlaceHolder: "MOOC sur l'intelligence artificielle",
      endDateLabel: "(Optionnel) Fin de l'histoire",
      endDateNowLabel: "Je suis encore dans l'histoire",
    },
  },
  PUBLICATION: {
    public: true,
    text: ' Publications / Articles',
    appends: 'articles',
    iconStoryMenu: 'book',
    color: 'rgb(0, 162, 174)',
    description:
      "Les publications ou articles de blog sont un excellent moyen de mettre en avant le savoir-faire. Je peux écrire un article sur mon domaine d'activité et l'ajouter ici ou répertorier des articles qui mettent en avant mes réalisations !",
    form: {
      header: '👇 Nouvel article',
      hasEndDate: false,
      hasStartDate: true,
      endDateCanBeNow: false,
      titleLabel: "Titre de l'article",
      startDateLabel: '(Optionnel) Date de parution',
      titlePlaceHolder: '',
      endDateLabel: '',
      endDateNowLabel: '',
    },
  },
  PROJECT: {
    public: true,
    text: 'Projets',
    appends: 'projets',
    iconStoryMenu: 'tool',
    color: 'rgb(60, 38, 187)',
    description:
      'Personnels comme professionnels, les projets sont le meilleur moyen de se faire reconnaître. Je peux lister mes projets ici. ',
    form: {
      header: '👇 Nouvelle histoire de projet',
      hasEndDate: false,
      hasStartDate: true,
      endDateCanBeNow: true,
      titleLabel: "Titre de l'histoire",
      startDateLabel: "(Optionnel) Moment de l'histoire",
      titlePlaceHolder: '',
      endDateLabel: '',
      endDateNowLabel: "Je suis encore dans l'histoire",
    },
  },
  MEETING: {
    public: true,
    text: 'Rencontres et évènements',
    appends: 'rencontres',
    iconStoryMenu: 'contacts',
    color: '#6d69df',
    description:
      "Si des rencontres personnelles ou des événements ont marqué l'élaboration de mon projet professionnel, je peux les mentionner ici.",
    form: {
      header: '👇 Nouvelle rencontre ou nouvel évènement',
      hasEndDate: false,
      hasStartDate: true,
      endDateCanBeNow: false,
      titleLabel: 'Titre',
      startDateLabel: "(Optionnel) Moment de la rencontre ou de l'évènement",
      titlePlaceHolder: '',
      endDateLabel: '',
      endDateNowLabel: '',
    },
  },
  INTEREST: {
    public: true,
    text: 'Intérêts',
    appends: 'interets',
    iconStoryMenu: 'heart',
    color: 'rgb(245, 106, 0)',
    description:
      "Je décris mes centres d'intérêt, hors professionnels. Si possible, en ajoutant des images, liens ou vidéos.",
    form: {
      header: "👇 Nouvelle histoire de centre d'intérêt",
      hasEndDate: false,
      hasStartDate: true,
      endDateCanBeNow: true,
      titleLabel: "Titre de l'histoire",
      startDateLabel: "(Optionnel) Moment de l'histoire",
      titlePlaceHolder: '',
      endDateLabel: '',
      endDateNowLabel: "Je suis encore dans l'histoire",
    },
  },
  AWARD: {
    public: true,
    text: 'Prix et récompenses',
    appends: 'prix',
    iconStoryMenu: 'trophy',
    color: '#D73964',
    description:
      "Si j'ai gagné des prix ou des récompenses, je peux le mentionner ici. Ils jouent un rôle important dans la perception d'un profil !",
    form: {
      header: '👇 Nouvelle histoire de prix remporté',
      hasEndDate: false,
      hasStartDate: true,
      endDateCanBeNow: false,
      titleLabel: "Titre de l'histoire",
      startDateLabel: "(Optionnel) Moment de l'histoire",
      titlePlaceHolder: '',
      endDateLabel: '',
      endDateNowLabel: '',
    },
  },
  FAIL: {
    public: true,
    text: 'Echecs professionnels',
    appends: 'echecs',
    iconStoryMenu: 'frown',
    color: '#94aab0',
    description:
      "Les échecs professionnels, et la manière dont je les ai dépassés sont une mine d'informations pour les recruteurs. J'en fais un atout 💪 !",
    form: {
      header: "👇 Nouvelle histoire d'échec professionnel",
      hasEndDate: true,
      hasStartDate: true,
      endDateCanBeNow: true,
      titleLabel: "Titre de l'histoire",
      startDateLabel: "(Optionnel) Début de l'histoire",
      titlePlaceHolder: '',
      endDateLabel: "(Optionnel) Fin de l'histoire",
      endDateNowLabel: "Je suis encore dans l'histoire",
    },
  },
  FREESTORY: {
    public: true,
    text: 'Histoire libre',
    appends: 'freestory',
    iconStoryMenu: 'cloud',
    color: '#40A9FF',
    description:
      "Je suis libre de sortir du cadre et de partager d'autres aspects de ma vie professionnelle.",
    form: {
      header: '👇 Nouvelle histoire libre',
      hasEndDate: true,
      hasStartDate: true,
      endDateCanBeNow: true,
      titleLabel: "Titre de l'histoire",
      startDateLabel: "(Optionnel) Début de l'histoire",
      titlePlaceHolder: '',
      endDateLabel: "(Optionnel) Fin de l'histoire",
      endDateNowLabel: "Je suis encore dans l'histoire",
    },
  },
  // LANGUAGES: {
  //   hideInNavigation: true,
  //   public: false,
  // },
  // SKILLS: {
  //   hideInNavigation: true,
  //   public: false,
  // },
}

export const USER_STORY_TYPE_ARRAY = Object.entries(
  USER_STORY_TYPE_ITERABLE
).map(([key, value]) => ({
  name: key,
  ...value,
}))

export const USER_LINK_ITERABLE = {
  WHIRE_PROFILE: {
    title: 'Votre profil public',
    registerHide: true,
    icon: 'static/links-logo-2.png',
  },
  PERSONAL: {
    title: 'Votre site internet',
    icon: '/static/links/vinyl.svg',
  },
  MAIL: {
    title: 'Adresse email',
    registerHide: true,
    icon: '/static/links/mail.svg',
  },
  RESUME: {
    title: 'CV format PDF',
    registerHide: true,
    icon: '/static/links/resume2.png',
  },
  LINKEDIN: {
    title: 'Profil Linkedin',
    icon: '/static/links/linkedin.svg',
  },
  GITHUB: {
    title: 'Dépôt Github',
    icon: '/static/links/github.svg',
  },
  TWITTER: {
    title: 'Profil Twitter',
    icon: '/static/links/twitter.svg',
  },
  YOUTUBE: {
    title: 'Chaîne Youtube',
    icon: '/static/links/youtube.svg',
  },
  VIMEO: {
    title: 'Chaîne Vimeo',
    icon: '/static/links/vimeo.svg',
  },
  DRIBBBLE: {
    title: 'Profil Dribbble',
    icon: '/static/links/dribbble.svg',
  },
  BEHANCE: {
    title: 'Profil Behance',
    icon: '/static/links/behance.svg',
  },
  MEDIUM: {
    title: 'Blog Medium',
    icon: '/static/links/medium.svg',
  },
  TUMBLR: {
    title: 'Blog Tumblr',
    icon: '/static/links/tumblr.svg',
  },
  DROPBOX: {
    title: 'DropBox',
    icon: '/static/links/dropbox.svg',
  },
  INSTAGRAM: {
    title: 'Compte Instagram',
    icon: '/static/links/instagram.png',
  },
  FLICKR: {
    title: 'Compte Flickr',
    icon: '/static/links/flickr.svg',
  },
  FACEBOOK: {
    title: 'Page Facebook',
    icon: '/static/links/facebook.svg',
  },
  'GOOGLE-PLUS': {
    title: 'Google +',
    icon: '/static/links/google-plus.svg',
  },
  SOUNDCLOUD: {
    title: 'Chaîne Soundcloud',
    icon: '/static/links/soundcloud.svg',
  },
  SPOTIFY: {
    title: 'Chaîne Spotify',
    icon: '/static/links/spotify.svg',
  },
  SKYPE: {
    title: 'Adresse Skype',
    icon: '/static/links/skype.svg',
  },
  OTHER: {
    title: 'Autre lien',
    icon: '/static/links/icone-link.png',
  },
}

export const INTERMEDIARY_STORY_TYPE_ITERABLE = {
  PAGE: {
    hideInStoriesMenu: true,
    public: true,
    force: true,
    text: 'Présentation',
    appends: '',
  },
  RECEPTION: {
    public: true,
    text: "Accueillir nos candidats (comment ça marche ?)/Modalités d'accueil de nos candidats",
    appends: 'acceuil-candidats',
    iconStoryMenu: 'contacts',
    color: '#6d69df',
    description:
      "Vous pouvez présenter ici les différentes possibilités qu'ont les employeurs souhaitant accueillir les personnes que vous accompagnez et en préciser les modalités.",
    form: {
      header: "👇 Nouvelle modalité d'accueil",
      hasEndDate: true,
      hasStartDate: true,
      endDateCanBeNow: true,
      titleLabel: 'Nom de la modalité',
      startDateLabel: 'Date de début',
      titlePlaceHolder: '',
      endDateLabel: 'Date de fin',
      endDateNowLabel: '',
    },
  },
  MEETING: {
    public: true,
    text: 'Nos évènements',
    appends: 'evenements',
    iconStoryMenu: 'contacts',
    color: '#6d69df',
    description:
      'Vous organisez des événements auxquels les employeurs sont invités à se joindre ou à contribuer par exemple (job dating, sessions de présentation de métiers...) ? Parlez-en ici.',
    form: {
      header: '👇 Nouvel évènement',
      hasEndDate: true,
      hasStartDate: true,
      endDateCanBeNow: true,
      titleLabel: 'Votre évènement',
      startDateLabel: 'Date de début',
      titlePlaceHolder: '',
      endDateLabel: 'Date de fin',
      endDateNowLabel: 'Evènement en cours',
    },
  },
  AWARD: {
    public: true,
    text: "Les success stories/Le coin des belles histoires/L'avis des autres",
    appends: 'recompenses',
    iconStoryMenu: 'trophy',
    color: '#D73964',
    description:
      'Rien de tel que de belles histoires pour rassurer un employeur et lui donner envie de rencontrer vos candidats. Racontez-les ici et incluez des témoignages si possible.',
    form: {
      header: '👇 Nouvelle histoire',
      hasEndDate: true,
      hasStartDate: true,
      endDateCanBeNow: true,
      titleLabel: "Nom de l'histoire",
      startDateLabel: "Date de l'histoire",
      titlePlaceHolder: '',
      endDateLabel: '',
      endDateNowLabel: '',
    },
  },
  PUBLICATION: {
    public: true,
    text: 'Articles et publications',
    appends: 'articles',
    iconStoryMenu: 'book',
    color: 'rgb(0, 162, 174)',
    description:
      'Les publications ou les articles sont un excellent moyen de mettre en avant le savoir-faire de votre structure.',
    form: {
      header: '👇 Ajouter un article/publication',
      hasEndDate: true,
      hasStartDate: true,
      endDateCanBeNow: true,
      titleLabel: '',
      startDateLabel: '',
      titlePlaceHolder: '',
      endDateLabel: '',
      endDateNowLabel: '',
    },
  },
  MORE: {
    public: true,
    text: "Plus d'informations",
    appends: 'information',
    iconStoryMenu: 'heart',
    color: 'rgb(245, 106, 0)',
    description:
      'Toutes les informations complémentaires que vous souhaitez donner à un employeur.',
    form: {
      header: "👇 Plus d'information",
      hasEndDate: false,
      hasStartDate: false,
      endDateCanBeNow: false,
      titleLabel: '',
      startDateLabel: '',
      titlePlaceHolder: '',
      endDateLabel: '',
      endDateNowLabel: '',
    },
  },
}

export const INTERMEDIARY_STORY_TYPE_ARRAY = Object.entries(
  INTERMEDIARY_STORY_TYPE_ITERABLE
).map(([key, value]) => ({
  name: key,
  ...value,
}))

export const COMPANY_LINK_ITERABLE = { ...USER_LINK_ITERABLE }
export const INTERMEDIARY_LINK_ITERABLE = { ...USER_LINK_ITERABLE }

export const DOMAIN = DOMAIN_NETLIFY[process.env.NODE_ENV].uri

export const ALGOLIA = ALGOLIA_API[process.env.NODE_ENV]

export const UPLOADCARE_PUBLIC_KEY = UPLOADCARE_PUBLIC[process.env.NODE_ENV]
export const UPLOADCARE_PRIVATE_KEY = UPLOADCARE_PRIVATE[process.env.NODE_ENV]

export const CONTACT_ME = CONTACT_ME_SERVER[process.env.NODE_ENV]

import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom'

import { UikSvg } from 'uikit/components';

import { SVG_URI } from 'utils/vars'

import cls from './createCards.scss'
import { AutoComplete } from 'antd';


const CreateCardsMenu = (props) => {
  return(
    <div className={cls.created_cards_menu}>
      <h2>👋 Hello !</h2>
      <p>Ici tu peux créer ta carte personnalisée et choisir le style que tu souhaites 🙂</p>
      {
        props.profile ? 
          <div className={cls.created_cards_menu_div}>
            <Link to={`/@${props.profile.slug}/cards1`}>
              <div className={cls.created_cards_menu_items}>
                  <UikSvg
                    alt="next"
                    height="200px"
                    src={`${SVG_URI}/card-basic.svg`}
                    width="150px"
                    style={{
                      marginTop: "-25px"
                    }}
                  />
                  <p>Une carte avec un design épuré pour un rendu plus professionnel</p>
              </div>
            </Link>
            <Link to={`/@${props.profile.slug}/cards2`}>
              <div className={cls.created_cards_menu_items}>
                  <UikSvg
                    alt="next"
                    height="200px"
                    src={`${SVG_URI}/card-edu1.svg`}
                    width="150px"
                    style={{
                      marginTop: "-25px"
                    }}
                  />
                  <p>Une carte haut en couleur pour un rendu plus accessible</p>
              </div>
            </Link>
          </div>
        :
          <div className={cls.created_cards_menu_div}>
            <Link to="/cards1">
              <div className={cls.created_cards_menu_items}>
                  <UikSvg
                    alt="next"
                    height="200px"
                    src={`${SVG_URI}/card-basic.svg`}
                    width="150px"
                    style={{
                      marginTop: "-25px"
                    }}
                  />
                  <p>Une carte avec un design épuré pour un rendu plus professionnel</p>
              </div>
            </Link>
            <Link to="/cards2">
              <div className={cls.created_cards_menu_items}>
                  <UikSvg
                    alt="next"
                    height="200px"
                    src={`${SVG_URI}/card-edu1.svg`}
                    width="150px"
                    style={{
                      marginTop: "-25px"
                    }}
                  />
                  <p>Une carte haut en couleur pour un rendu plus accessible</p>
              </div>
            </Link>
          </div>
      }
    </div>
  )
}

export default CreateCardsMenu

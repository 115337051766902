// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oHNWACHSfTWNsPlS8xUZ{padding:16px 30px 30px}", "",{"version":3,"sources":["webpack://./src/uikit/components/UikNavLinkTwoContainer/nav-link-two-container.module.scss"],"names":[],"mappings":"AAAA,sBACE,sBAAA","sourcesContent":[".wrapper {\n  padding: 16px 30px 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "oHNWACHSfTWNsPlS8xUZ"
};
export default ___CSS_LOADER_EXPORT___;

import React, { Component, node } from 'react'

/*
  Example:
  <KeyListener
    actions={[
      {
        keyCode: 27,
        method: () => {
          console.log('Escape called');
        },
      },
    ]}
    listenerType="keydown"
  >
    <Modal />
  </KeyListener>
*/

class KeyListener extends Component {
  componentDidMount() {
    document.addEventListener(this.props.listenerType, this.onKeyEvent, false)
  }
  componentWillUnmount() {
    document.removeEventListener(
      this.props.listenerType,
      this.onKeyEvent,
      false
    )
  }
  onKeyEvent = (event) => {
    const action = this.props.actions.find((e) => e.keyCode === event.keyCode)
    if (action && action.method) {
      action.method()
    }
    event.preventDefault()
  }
  render() {
    const { ComponentWrapper, children, ...rest } = this.props
    return <ComponentWrapper {...rest}>{children}</ComponentWrapper>
  }
}

KeyListener.defaultProps = {
  ComponentWrapper: 'div',
}

KeyListener.displayName = 'KeyListener'

export default KeyListener

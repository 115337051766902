import * as React from 'react'
import classnames from 'classnames'

import cls from './nav-title.module.scss'

const UikNavTitle = ({ children, className, ...rest }) => (
  <span className={classnames(cls.wrapper, className)} {...rest}>
    {children}
  </span>
)

UikNavTitle.defaultProps = {
  className: null,
  children: null,
}

export default UikNavTitle

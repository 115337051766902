import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ForwardState extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    ComponentWrapper: PropTypes.string || PropTypes.node,
    init: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    setStatePropName: PropTypes.string,
    statePropName: PropTypes.string,
  }
  static defaultProps = {
    ComponentWrapper: 'div',
    init: null,
    setStatePropName: 'setState',
    statePropName: 'state',
  }

  constructor(props) {
    super(props)
    this.state = props.init || {}
    this.setThisState = this.setThisState.bind(this)
  }

  setThisState(newState, cb) {
    this.setState(newState, cb || null)
  }

  render() {
    const {
      children,
      setStatePropName,
      statePropName,
      ComponentWrapper,
      ...rest
    } = this.props

    return (
      <ComponentWrapper {...rest}>
        {children({
          [statePropName]: this.state,
          [setStatePropName]: this.setThisState,
        })}
      </ComponentWrapper>
    )
  }
}

//ForwardState.displayName = 'ForwardState';

export default ForwardState

import React, { useContext } from 'react'
import CookieConsent from 'react-cookie-consent'
import { StoreContext } from 'contexts/Store'
import { Link } from 'react-router-dom'
import cls from './cookie.scss'
import { IMG_URI } from '../../utils/vars'

const Cookies = () => {
  const {
    normalWidth: { normalWidth },
  } = useContext(StoreContext)

  return (
    <CookieConsent
      style={{
        background: 'white',
        color: '#3E3F42',
        left: 'auto',
        right: '0',
        alignItems: 'center',
        paddingRight: '8%',
        border: '2px solid  #eee',
        borderTopLeftRadius: '70px',
        borderBottomLeftRadius: '70px',
        width:
          normalWidth &&
          /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
            navigator.userAgent
          ) === false
            ? 'calc(100% - 80px)'
            : /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
                navigator.userAgent
              ) && !location.pathname.includes('edit' || 'nouvelle')
            ? '97%'
            : '97%',
      }}
      buttonText="J'ai compris"
      buttonStyle={{
        background: '#6977FF',
        color: '#FFFFFF',
        margin:
          normalWidth &&
          /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
            navigator.userAgent
          ) === false
            ? '15px 190px 15px 15px'
            : '10px 60px 15px',
      }}
      // debug={true}
      expires={395}
    >
      <div className={cls.wrapper__content}>
        <img src={`${IMG_URI}/cookie.png`} alt="Cookie" />
        <p>
          « En poursuivant votre navigation, vous acceptez l’utilisation de
          cookies que nous utilisons pour vous proposer les services les plus
          adaptés à votre profil et faire des mesures de statistiques
          d’audience.&nbsp;
          <Link to="/charte-cookies">En savoir plus</Link>.
        </p>
        <p>
          Vous acceptez également notre politique de confidentialité (
          <Link to="/privacy">en savoir plus ici</Link>) ainsi que nos
          conditions générales d'utilisation (
          <Link to="/cgu">en savoir plus ici</Link>).&nbsp;»
        </p>
      </div>
    </CookieConsent>
  )
}

export default Cookies

import React, { node } from 'react'

import cls from './base-header.module.scss'

const UikBaseHeader = ({ children, position }) => (
  <div className={cls.wrapper} style={{ position }}>
    {children}
  </div>
)

UikBaseHeader.defaultProps = {
  position: 'inherit',
}

UikBaseHeader.displayName = 'UikBaseHeader'

export default UikBaseHeader

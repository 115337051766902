import React, { useState, useRef, useCallback } from 'react'
import ReactToPrint from 'react-to-print'
import QRCode  from 'qrcode.react'


import { SelectTagsAspi, SelectTagsAtout } from './SelectTags'
import ListTags from './ListTags'

import { IMG_URI, SVG_URI } from 'utils/vars'
import cls from './createCards.scss'

const CreateCardsEdu = React.forwardRef((props, ref) => {
  // since prisma migration v1 to v2 there is a bug that duplicate tags when you edit profile. this filter duplicate tags
  const tags = props.profile && props.profile.tags.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
  const tagsInterest = props.profile && props.profile ? tags.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i).filter(t => t.type === "INTEREST").map(t=> ({label: t.name, value: t.name})) : null
  const otherTags = props.profile && props.profile  ? tags.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i).filter(t => t.type !== "INTEREST").map(t=> ({label: t.name, value: t.name})) : null
  const tagsWork = props.profile && props.profile  ? tags.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i).filter(t => t.type === "WORK" || t.type === "DOMAIN").map(t=> ` ${t.name}`).toString() : null
  const imgProfile = props.profile && props.profile.image ? `https://ucarecdn.com/${props.profile.image}/` : `${IMG_URI}/profile.png`

  const generateCard = () => {
    setTagsAspi(tagsInterest)
    setTagsAtouts(otherTags)
    setJob(tagsWork)
    setName(props.profile.title)
    setHook(props.profile.summary)
    setUrl(`https://www.whire.me/@${props.profile.slug}`)
    setImg(imgProfile)
  }
  const [img, setImg] = useState(`${IMG_URI}/profile.png`)
  const [name, setName] = useState('Prénom NOM')
  const [job, setJob] = useState('Intitulé métier visé')
  const [hook, setHook] = useState("Phrase d'accroche sur 1 ou 2 lignes")

  const arrLabelTagsAspi = [
    'Tag aspiration 1',
    'Tag aspiration 2',
    'Tag aspiration 3',
  ]
  const [tagsAspi, setTagsAspi] = useState([])

  const arrLabelTagsAtouts = ['Tag atout 1', 'Tag atout 2', 'Tag atout 3']
  const [tagsAtouts, setTagsAtouts] = useState([])

  const [url, setUrl] = useState('https://www.whire.me/@MAPAGE')

  const [themeTagsAspi, setThemeTagsAspi] = useState(cls.yellow)
  const [themeTagsAtouts, setThemeTagsAtouts] = useState(cls.yellow)
  const [sizeTagsAspi, setSizeTagsAspi] = useState(15)
  const [sizeTagsAtout, setSizeTagsAtout] = useState(15)

  const componentRef = useRef()
  const pageStyle = `
        @page {
            size: 200mm 200mm;
            margin: 0;
        }

        @media all {
            .pagebreak {
            display: none;
            }
        }

        @media print {
            .pagebreak {
            page-break-before: always;
            }
        }
        `

  //   const handlePrint = useReactToPrint({
  //     content: () => componentRef.current
  //   });
  return (
    <div className={cls.created_card}>
      <div ref={componentRef} className={cls.cardedu}>
        <img
          className={cls.img_model}
          src={`${IMG_URI}/modele.png`}
          alt="Modele"
        />
        <div className={cls.wrapper_card}>
          <div className={cls.header_card}>
            <img className={cls.photo_profil} src={img} alt="" />
            <div className={cls.title_card}>
              <h2 className={cls.name}>
                {name}
                <span className={cls.emoji}> 👋</span>
              </h2>
              <p className={cls.job}>
                <span className={cls.emoji}> 🚀 </span>
                {job}
              </p>
              <p className={cls.hook}>
                {hook}
                <span className={cls.emoji}>😀</span>
              </p>
            </div>
            <img
              className={cls.logo}
              src={`${SVG_URI}/Logo-whire_32x32.svg`}
              alt="logo"
            />
          </div>
          <div className={cls.body_card}>
            <div className={cls.wrapper_tagsedu}>
              <div className={cls.icon_tagedu}>
                <img src={`${SVG_URI}/coeur.svg`} alt="Coeur" />
              </div>
              <div className={cls.tags_listedu}>
                <ListTags
                  label={arrLabelTagsAspi}
                  tags={tagsAspi ? tagsAspi.slice(0, 3): []}
                  style={themeTagsAspi}
                  size={sizeTagsAspi}
                />
              </div>
            </div>
            <div className={cls.wrapper_tagsedu}>
              <div className={cls.icon_tagedu}>
                <img src={`${SVG_URI}/star.svg`} alt="Star" />
              </div>
              <div className={cls.tags_listedu}>
                <ListTags
                  label={arrLabelTagsAtouts}
                  tags={tagsAtouts ? tagsAtouts.slice(0, 5): []}
                  style={themeTagsAtouts}
                  size={sizeTagsAtout}
                />
              </div>
            </div>
          </div>
          <div className={cls.footer_cardedu}>
            <p>Qui se cache derrière mon CV ?</p>
            <div className={cls.link}> 
              <QRCode value={url} size={50}/>
              <a href={url} target="_blank" rel="noopener">
                👉 {url}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={cls.dashboard}>
        {
          props.profile ?
            <div className={cls.generateDiv}>
              <button className={cls.generate} onClick={generateCard}>Générer une carte Whire</button>
            </div>
          : null
        }
        <div className={cls.input_file}>
          <label htmlFor="file">Ajouter votre photo :</label>
          <input
            className={cls.file}
            name="file"
            type="file"
            onChange={(e) => setImg(URL.createObjectURL(e.target.files[0]))}
          />
        </div>
        <div className={cls.input_title}>
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <input
            type="text"
            onChange={(e) => setJob(e.target.value)}
            value={job}
          />
          <input
            type="text"
            onChange={(e) => setHook(e.target.value)}
            value={hook}
          />
        </div>
        <div className={cls.input_tags}>
          <h3>Tags Aspirations</h3>
          <label htmlFor="tagsAspi">
            👉 Je choisis dans la liste suivante les tags “Aspirations”
          </label>
          <SelectTagsAspi values={tagsAspi} change={(value) => setTagsAspi(value)} />
          {
            tagsAspi && tagsAspi.length > 3 ?
              <p className={cls.error}>Seulement les 3 premiers tags sont affichés</p>
            : null
          }
          <div className={cls.theme}>
            <div className={cls.colors}>
              <span
                onClick={() => setThemeTagsAspi(cls.yellow)}
                className={cls.yellow}
              />
              <span
                onClick={() => setThemeTagsAspi(cls.green)}
                className={cls.green}
              />
              <span
                onClick={() => setThemeTagsAspi(cls.blue)}
                className={cls.blue}
              />
              <span
                onClick={() => setThemeTagsAspi(cls.red)}
                className={cls.red}
              />
              <span
                onClick={() => setThemeTagsAspi(cls.purple)}
                className={cls.purple}
              />
            </div>
            <div className={cls.size}>
              <input
                type="number"
                min={4}
                max={30}
                value={sizeTagsAspi}
                onChange={(e) => setSizeTagsAspi(e.target.value)}
              />
              <span>px</span>
            </div>
          </div>
        </div>
        <div className={cls.input_tags}>
          <h3>Tags Atouts</h3>
          <label htmlFor="tagsAspi">
            👉 J'ajoute d’autres tags pour mettre en avant mes atouts principaux
            (compétences, qualités, outils maîtrisés, type de publics avec
            lesquelles je veux travailler…).
          </label>
          <SelectTagsAtout values={tagsAtouts} change={(value) => setTagsAtouts(value)} />
          {
            tagsAtouts && tagsAtouts.length > 4 ?
              <p className={cls.error}>Seulement les 5 premiers tags sont affichés</p>
            : null
          }
          <div className={cls.theme}>
            <div className={cls.colors}>
              <span
                onClick={() => setThemeTagsAtouts(cls.yellow)}
                className={cls.yellow}
              />
              <span
                onClick={() => setThemeTagsAtouts(cls.green)}
                className={cls.green}
              />
              <span
                onClick={() => setThemeTagsAtouts(cls.blue)}
                className={cls.blue}
              />
              <span
                onClick={() => setThemeTagsAtouts(cls.red)}
                className={cls.red}
              />
              <span
                onClick={() => setThemeTagsAtouts(cls.purple)}
                className={cls.purple}
              />
            </div>
            <div className={cls.size}>
              <input
                type="number"
                min={4}
                max={30}
                value={sizeTagsAtout}
                onChange={(e) => setSizeTagsAtout(e.target.value)}
              />
              <span>px</span>
            </div>
          </div>
        </div>
        <div className={cls.input_url}>
          <input
            type="text"
            onChange={(e) => setUrl(e.target.value)}
            value={url}
          />
        </div>
        <div className={cls.print}>
          <ReactToPrint
            trigger={() => <button>Télécharger / Imprimer</button>}
            content={() => componentRef.current}
            pageStyle={pageStyle}
          />
          {/* <button onClick={() => window.print()}>Télécharger / Imprimer</button> */}
        </div>
      </div>
    </div>
  )
})

export default CreateCardsEdu

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._CQFtQjbJiDLyFF2btUo{height:100%;overflow-y:auto;flex-grow:1}.Lf6wfdvNXeORELuGAare{padding:15px;display:flex;flex-direction:column}.Lf6wfdvNXeORELuGAare.T0pZfQvKdAt9vcqAMNv4,.Lf6wfdvNXeORELuGAare.z1gUPc8ogwFHSKHDpgTd{max-width:1170px;margin:0 auto}.Lf6wfdvNXeORELuGAare.T0pZfQvKdAt9vcqAMNv4,.Lf6wfdvNXeORELuGAare.z1gUPc8ogwFHSKHDpgTd{margin:0 auto}.Lf6wfdvNXeORELuGAare.z1gUPc8ogwFHSKHDpgTd{max-width:885px}.Lf6wfdvNXeORELuGAare.jg3oypyirzLB6XAtVeTY{max-width:1170px}", "",{"version":3,"sources":["webpack://./src/uikit/containers/UikLayoutMain/layout-main.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,eAAA,CACA,WAAA,CAKA,sBACE,YAAA,CAGA,YAAA,CACA,qBAAA,CACA,sFACE,gBAAA,CACA,aAAA,CAGF,sFACE,aAAA,CAGF,2CACE,eAAA,CAGF,2CACE,gBAAA","sourcesContent":[".wrapper {\n  height: 100%;\n  overflow-y: auto;\n  flex-grow: 1;\n\n}\n\n\n  .wrapperInner {\n    padding: 15px;\n    // to prevent collapsing margins\n    // https://css-tricks.com/what-you-should-know-about-collapsing-margins/\n    display: flex;\n    flex-direction: column;\n    &.contentCenter, &.contentCenterMedium {\n      max-width: 1170px;\n      margin: 0 auto;\n    }\n\n    &.contentCenter, &.contentCenterMedium {\n      margin: 0 auto;\n    }\n\n    &.contentCenterMedium {\n      max-width: 885px;\n    }\n\n    &.contentLeft {\n      max-width: 1170px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_CQFtQjbJiDLyFF2btUo",
	"wrapperInner": "Lf6wfdvNXeORELuGAare",
	"contentCenter": "T0pZfQvKdAt9vcqAMNv4",
	"contentCenterMedium": "z1gUPc8ogwFHSKHDpgTd",
	"contentLeft": "jg3oypyirzLB6XAtVeTY"
};
export default ___CSS_LOADER_EXPORT___;

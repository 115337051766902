import * as React from 'react'
import classnames from 'classnames'

import UikAvatarStatus from './UikAvatarStatus'
import UikAvatarImage from './UikAvatarImage'

import cls from './avatar.module.scss'

export const UikAvatar = ({
  name,
  imageId,
  imageModifier,
  avatarPlaceholder,
  textTop,
  className,
  highlighted,
  actionIcon,
  status,
  size,
  textBottom,
  draft,
  ...rest
}) => {
  // console.log(imageId);
  return (
    <div
      className={classnames(cls.wrapper, className, {
        [cls.highlighted]: highlighted,
        [cls[size]]: size,
      })}
      {...rest}
    >
      <div className={classnames(cls.avatarWrapper)}>
        {imageId ? (
          <UikAvatarImage
            className={cls.avatar}
            imgUrl={
              imageModifier
                ? `https://ucarecdn.com/${imageId}/${imageModifier}`
                : `https://ucarecdn.com/${imageId}/`
            }
          />
        ) : (
          <div
            className={classnames(cls.avatarPlaceholder, {
              [avatarPlaceholder.color]: avatarPlaceholder.color,
            })}
          >
            {avatarPlaceholder.content}
          </div>
        )}
        {actionIcon && <div className={cls.avatarAction}>{actionIcon}</div>}
      </div>
      {name && (
        <div className={cls.info}>
          {textTop && <span className={cls.textTop}>{textTop}</span>}
          {name && (
            <span
              className={cls.name}
              style={draft ? { color: 'rgba(62,63,66,0.4)' } : null}
            >
              {name}
            </span>
          )}
          {textBottom && <span className={cls.textBottom}>{textBottom}</span>}
        </div>
      )}
      {status && <UikAvatarStatus status={status} />}
    </div>
  )
}

export default UikAvatar

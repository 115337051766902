import * as React from 'react'
import classnames from 'classnames'

import cls from './scroll-horizontal.module.scss'

const UikSHorizontalScrollArea = ({ children, className, ...rest }) => (
  <div className={classnames(cls.wrapper, className)} {...rest}>
    {children}
  </div>
)

UikSHorizontalScrollArea.defaultProps = {
  className: null,
  children: null,
}

export default UikSHorizontalScrollArea

import React from 'react'

import TextWidget from 'components/TextWidget'
import UikContainerVertical from 'uikit/containers/UikContainerVertical'
import '../initStyles/App.scss'

export const CharterContent = () => (
  <div
    style={{
      marginLeft: '2em',
    }}
  >
    <h1>CHARTE D’UTILISATION DES COOKIES</h1>
    <h2>QU’EST-CE QU’UN COOKIE ? </h2>
    <p>
      Un « cookie », ce n’est pas seulement un petit gâteau aux pépites de
      chocolat, c’est aussi un petit fichier envoyé à votre navigateur par le
      site internet que vous consultez et stocké sur votre ordinateur ou tout
      autre terminal que vous utilisez (tel que smartphone ou tablette
      numérique).
      <br></br>
      <br></br>
      Pendant toute la durée de leur validité, les cookies que le site
      accessible à partir de l’adresse{' '}
      <a href="https://www.whire.me">www.whire.me</a> (ci-après le « Site »)
      envoie sur votre navigateur permet de recueillir différents types
      d’informations relatives à votre utilisation du Site (telles que votre
      parcours, vos préférences sur le Site, etc.). Ces cookies nous permettent
      d’améliorer votre expérience de navigation et les services proposés sur le
      Site. En aucun cas, ils permettent à Whire de vous identifier en tant
      qu’individu.{' '}
    </p>
    <h2>QUELS SONT LES DIFFÉRENTS TYPES DE COOKIES PRÉSENTS SUR LE SITE ?</h2>
    <p>Whire utilise trois catégories de cookies :</p>
    <h3>1. Des cookies strictement nécessaires au fonctionnement du Site</h3>
    <p>
      Ces cookies enregistrent des informations techniques (telles que par
      exemple des identifiants de session) lors de la navigation d’une page à
      une autre sur le Site. Ils sont nécessaires à l’utilisation du Site,
      permettent sécuriser votre connexion et d’utiliser les principales
      fonctionnalités du Site.
      <br></br>
      <br></br>
      La suppression de ces cookies empêche une utilisation normale du Site.{' '}
    </p>
    <h3>2. Des cookies de fonctionnalités</h3>
    <p>
      Ces cookies permettent notamment de vous reconnaître lors d’une nouvelle
      visite sur le Site et ainsi d’optimiser votre expérience utilisateur en
      adaptant la présentation du Site aux préférences d’affichage de votre
      ordinateur, par exemple.
      <br></br>
      <br></br>
      Ces cookies demeurent sur votre terminal jusqu’à expiration de leur durée
      de vie ou jusqu’à leur supression à l’aide des fonctionnalités du
      navigateur.
    </p>
    <h3>3. Des cookies de mesure d’audience Google analytics</h3>
    <p>
      Ces cookies sont utilisés pour collecter de l’information, de manière
      anonyme, sur les utilisateurs des sites web. Ils permettent de
      comptabiliser les visiteurs et d’identifier la manière dont ils utilisent
      le Site. Ces cookies sont déposés et lus sur votre équipement et dès que
      vous accédez à un site Internet utilisant le service « Google Analytics ».
      <br></br>
      <br></br>
      Vous pouvez consulter la politique de confidentialité de Google via ce
      lien : 
      <a href="https://support.google.com/analytics/answer/6004245?hl=fr">
        https://support.google.com/analytics/answer/6004245?hl=fr
      </a>
      .{' '}
    </p>
    <h2>QUELLE EST LA DURÉE DE VALIDITÉ DES COOKIES ?</h2>
    <p>
      Les cookies déposés sur votre terminal ont une durée de vie maximum de 13
      mois. Pour les Utilisateurs ayant ouvert un Compte d’utilisateur, leur
      validité est, en outre, limitée à la durée de leur session.{' '}
    </p>
    <h2>COMMENT ACCEPTER, REFUSER OU SUPPRIMER LES COOKIES ?</h2>
    <p>
      Préalablement à l’utilisation du Site, vous avez exprimé votre
      consentement au dépôt et à l’utilisation des cookies en poursuivant votre
      navigation après avoir pris connaissance de la bannière d’information
      affichée lors de votre première visite.
      <br></br>
      <br></br>
      Vous pouvez néanmoins à tout moment retirer votre consentement au dépôt de
      cookies ou les supprimer. Pour ce faire, vous pouvez gérer les cookies
      utilisés par le Site en paramétrant votre navigateur suivant les modalités
      exposées ci-dessous.
      <br></br>
      <br></br>
      Pour modifier vos paramètres, il convient de se reporter aux pages d’aides
      de votre navigateur :
      <br></br>
      <br></br>
      Internet ExplorerTM :{' '}
      <a href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies">
        windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
      </a>
      <br></br>
      <br></br>
      Google ChromeTM :{' '}
      <a href="https://support.google.com/chrome/#topic=7438008">
        www.support.google.com/chrome/bin/answer.py
      </a>
      <br></br>
      SafariTM :{' '}
      <a href="http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html">
        http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html
      </a>
      <br></br>
      MozillaTM :{' '}
      <a href="http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies">
        http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies
      </a>
      <br></br>
      OperaTM :{' '}
      <a href="http://help.opera.com/Windows/10.20/fr/cookies.html">
        http://help.opera.com/Windows/10.20/fr/cookies.html.
      </a>
      <br></br>
      <br></br>
      Vous êtes informé qu’en fonction du paramétrage choisi, les conditions
      d’accès au Site et à ses fonctionnalités peuvent être modifiées ou
      limitées, ce dont Whire ne saurait être tenue pour responsable.
      <br></br>
      <br></br>
      Par ailleurs, en refusant le dépôt d’un cookie suivant les paramètres
      exposés ci-avant, un cookie est installé et conservé sur votre navigateur
      afin de vous identifier comme personne ayant refusé l’installation de
      cookies.
      <br></br>
      <br></br>
      Si vous effacez ce cookie de votre navigateur ou si vous vous connectez à
      partir d’un navigateur Internet différent ou d’un autre terminal, vous
      devriez entreprendre la même procédure de refus de dépôt des cookies.
      <br></br>
      <br></br>
      N’hésitez pas à contacter Whire pour toute question que vous pourriez
      avoir sur la présente charte d’utilisation des cookies : kayoum@whire.me{' '}
    </p>
  </div>
)

export default () => (
  <div className="App">
    <UikContainerVertical>
      <TextWidget
        content={{
          pages: 'Charter',
        }}
      />
    </UikContainerVertical>
  </div>
)

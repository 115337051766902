import * as React from 'react'
import classnames from 'classnames'

import cls from './form-input-group.module.scss'

const UikFormInputGroup = ({
  children,
  className,
  Component,
  direction,
  ...rest
}) => (
  <Component
    className={classnames(cls.wrapper, className, {
      [cls.horizontal]: direction === 'horizontal',
    })}
    {...rest}
  >
    {children}
  </Component>
)

UikFormInputGroup.defaultProps = {
  className: null,
  Component: 'div',
  direction: 'vertical',
}

export default UikFormInputGroup

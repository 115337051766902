const urlRegex =
  /((?:https?(?::\/\/))(?:www\.)?(?:[a-zA-Z\d-_.]+(?:\.[a-zA-Z\d]{2,})|localhost)(?:(?:[-a-zA-Z\d:%_+.~#!?&//=@]*)(?:[,](?![\s]))*)*)/g

function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText()
  let matchArr
  let start
  matchArr = regex.exec(text)
  while (matchArr !== null) {
    start = matchArr.index
    callback(start, start + matchArr[0].length)
    matchArr = regex.exec(text)
  }
}

// contentState available as last argument
function handleLinkStrategy(contentBlock, callback) {
  findWithRegex(urlRegex, contentBlock, callback)
}

export default handleLinkStrategy

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h80rV0AUHVgPv4hSsAA3{padding:23px 30px;display:flex;justify-content:space-between;align-items:center;margin-bottom:1em}.h80rV0AUHVgPv4hSsAA3:not(:last-child){border-bottom:1px solid #eaedf3}.h80rV0AUHVgPv4hSsAA3 h3{font-weight:500;font-size:1.125rem;line-height:1.75rem}.h80rV0AUHVgPv4hSsAA3 h3+*{margin-top:0}.h80rV0AUHVgPv4hSsAA3.hBg7vQQjFcawdMC563Bu{padding-bottom:5px;border-bottom:none}", "",{"version":3,"sources":["webpack://./src/uikit/components/UikWidgetHeader/widget-title.module.scss"],"names":[],"mappings":"AACA,sBACE,iBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,iBAAA,CACA,uCACE,+BAAA,CAEF,yBAEE,eAAA,CACA,kBAAA,CACA,mBAAA,CACA,2BACE,YAAA,CAGJ,2CACE,kBAAA,CACA,kBAAA","sourcesContent":["@import 'src/initStyles/_vars.scss';\n.wrapper {\n  padding: 23px 30px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 1em;\n  &:not(:last-child) {\n    border-bottom: 1px solid $borderColor;\n  }\n  h3 {\n    /* Title: */\n    font-weight: 500;\n    font-size: 1.125rem;\n    line-height: 1.75rem;\n    + * {\n      margin-top: 0;\n    }\n  }\n  &.noDivider {\n    padding-bottom: 5px;\n    border-bottom: none;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "h80rV0AUHVgPv4hSsAA3",
	"noDivider": "hBg7vQQjFcawdMC563Bu"
};
export default ___CSS_LOADER_EXPORT___;

import * as React from 'react'
import classnames from 'classnames'
import cls from './widget-title.module.scss'

const UikWidgetHeader = ({
  children,
  className,
  Component = 'div',
  noDivider,
  rightEl,
  draft,
  ...rest
}) => (
  <Component
    className={classnames(cls.wrapper, className, {
      [cls.noDivider]: noDivider,
    })}
    {...rest}
  >
    <h3 style={draft ? { color: 'rgba(62,63,66,0.4)' } : null}>{children}</h3>
    {rightEl}
  </Component>
)

UikWidgetHeader.defaultProps = {
  className: null,
  Component: 'div',
  noDivider: false,
  rightEl: null,
}

export default UikWidgetHeader

import * as React from 'react'
import classnames from 'classnames'

import cls from './nav-divider.module.scss'

const UikTopBarMenuDivider = ({ className, ...rest }) => (
  <div className={classnames(cls.wrapper, className)} {...rest} />
)

UikTopBarMenuDivider.defaultProps = {
  className: null,
}

export default UikTopBarMenuDivider

import React, { useRef, useState } from 'react'
import cls from './formDemo.scss'
import { Link } from 'react-router-dom'
import { UikButton } from '../../../uikit/components'
import axios from 'axios'
import { IMG_URI, SVG_URI } from '../../../utils/vars'

const Demo = () => {
  const inputFirstname = useRef(null)
  const inputLastname = useRef(null)
  const inputEmail = useRef(null)
  const inputPhone = useRef(null)
  const inputOrg = useRef(null)
  const inputObjet = useRef(null)
  const inputMessage = useRef(null)

  const [showConfirmationSend, setShowConfirmationSend] = useState(false)
  const [showErrorSend, setShowErrorSend] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    const body = {
      firstName: inputFirstname.current.value,
      lastName: inputLastname.current.value,
      email: inputEmail.current.value,
      phone: inputPhone.current.value,
      org: inputOrg.current.value,
      objet: inputObjet.current.value,
      message: inputMessage.current.value,
    }

    axios({
      method: 'POST',
      url: `https://us-central1-send-mail-fcf88.cloudfunctions.net/sendDemo?firstName=${body.firstName}&&lastName=${body.lastName}&&email=${body.email}&&phone=${body.phone}&&org=${body.org}&&objet=${body.objet}&&message=${body.message}`,
      data: JSON.stringify(body),
    })
      .then((res) => {
        setShowConfirmationSend(true)
        console.log(res)
      })
      .catch((err) => {
        setShowErrorSend(true)
        console.log(err)
      })
  }

  return (
    <section className={cls.background}>
      <div className={cls.container__demo}>
        <div className={cls.left}>
          {window.location.pathname === '/entreprise/rejoindreleclub' ? (
            <h1 className={cls.titleDemo}>Rejoindre le club</h1>
          ) : (
            <h1 className={cls.titleDemo}>Demander une démo</h1>
          )}
          <img src={`${IMG_URI}/presentationVideo.png`} alt="demo" />
        </div>
        <div className={cls.right}>
          <UikButton className={cls.close} Component={Link} to="/org" success>
            <img src={`${SVG_URI}/remove.svg`} alt="close" />
          </UikButton>
          <form onSubmit={handleSubmit}>
            <div className={cls.wrapper__input}>
              <label htmlFor="firstname">
                Prénom<em>* </em>:
              </label>
              <input
                ref={inputFirstname}
                name="firstname"
                type="text"
                placeholder="Insérer votre prénom"
                required
              />
            </div>
            <div className={cls.wrapper__input}>
              <label htmlFor="lastname">
                Nom<em>*</em> :
              </label>
              <input
                ref={inputLastname}
                name="lastname"
                type="text"
                placeholder="Insérer votre nom"
                required
              />
            </div>
            <div className={cls.wrapper__input}>
              <label htmlFor="mail">
                eMail<em>*</em> :
              </label>
              <input
                ref={inputEmail}
                name="mail"
                type="email"
                placeholder="Insérer votre email"
                required
              />
            </div>
            <div className={cls.wrapper__input}>
              <label htmlFor="phone">Téléphone :</label>
              <input
                ref={inputPhone}
                name="phone"
                type="text"
                placeholder="Insérer votre numéro de téléphone"
              />
            </div>
            <div className={cls.wrapper__input}>
              {window.location.pathname === '/entreprise/rejoindreleclub' ? (
                <>
                  <label htmlFor="org">
                    Entreprise<em>*</em> :
                  </label>
                  <input
                    ref={inputOrg}
                    name="org"
                    type="text"
                    placeholder="Votre entreprise"
                    required
                  />
                </>
              ) : (
                <>
                  <label htmlFor="org">
                    Organisme<em>*</em> :
                  </label>
                  <input
                    ref={inputOrg}
                    name="org"
                    type="text"
                    placeholder="Nom de votre organisme/structure"
                    required
                  />
                </>
              )}
            </div>
            <div className={cls.wrapper__input}>
              <label htmlFor="objet">Sujet :</label>
              <input
                ref={inputObjet}
                name="objet"
                type="text"
                placeholder="Insérer votre sujet"
              />
            </div>
            <div className={cls.wrapper__input}>
              <label htmlFor="objet">
                Votre message <em>*</em> :
              </label>
              <textarea
                ref={inputMessage}
                name="message"
                cols="30"
                rows="10"
                required
              />
            </div>
            {showConfirmationSend ? (
              <div className={cls.confirmationMessage}>
                <p>Votre demande a bien été envoyée :)</p>
              </div>
            ) : null}
            {showErrorSend ? (
              <div className={cls.errorMessage}>
                <p>
                  Une erreur est survenue... Votre demande n'a pas été envoyée
                  :(
                </p>
              </div>
            ) : null}
            <button className={cls.button__submit} type="submit">
              Envoyer
            </button>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Demo

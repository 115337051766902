// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JTNBrz9azvRjo4UFKeZt{padding:15px}.JTNBrz9azvRjo4UFKeZt.Py_GqFAOB4ODzxeRk0sF{max-width:1170px;margin:0 auto}.a7O8RmuQF_ASskgYoi6c{margin:15px}", "",{"version":3,"sources":["webpack://./src/uikit/containers/UikContent/content.module.scss"],"names":[],"mappings":"AACA,sBACE,YAAA,CACA,2CACE,gBAAA,CACA,aAAA,CAIJ,sBACE,WAAA","sourcesContent":["\n.wrapper {\n  padding: 15px;\n  &.contentCenter {\n    max-width: 1170px;\n    margin: 0 auto;\n  }\n}\n\n.item {\n  margin: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "JTNBrz9azvRjo4UFKeZt",
	"contentCenter": "Py_GqFAOB4ODzxeRk0sF",
	"item": "a7O8RmuQF_ASskgYoi6c"
};
export default ___CSS_LOADER_EXPORT___;

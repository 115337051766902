// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Xf61C95Q4j4FGSSx6i_K{width:100%;text-align:center}", "",{"version":3,"sources":["webpack://./src/containers/Login/login.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,iBAAA","sourcesContent":[".btnAction {\n  width: 100%;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnAction": "Xf61C95Q4j4FGSSx6i_K"
};
export default ___CSS_LOADER_EXPORT___;

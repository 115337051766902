import * as React from 'react'
import classnames from 'classnames'

import IconWrap from '../UikonWrap'

import cls from './widget-link.module.scss'

const UikWidgetLink = ({
  className,
  Component = 'a',
  icon,
  title,
  rightEl,
  draft,
  ...rest
}) => (
  <Component className={classnames(cls.wrapper, className)} {...rest}>
    {icon && <IconWrap name="profile" className={cls.icon} />}
    <span
      className={cls.title}
      style={draft ? { color: 'rgba(62,63,66,0.4)' } : null}
    >
      {title || rest.name}
    </span>
    {rightEl && <span className={cls.rightEl}>{rightEl}</span>}
  </Component>
)

UikWidgetLink.defaultProps = {
  className: null,
  Component: 'a',
  icon: null,
  rightEl: null,
}

export default UikWidgetLink

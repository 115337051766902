import * as React from 'react'
import classnames from 'classnames'

import cls from './headline.module.scss'

const UikHeadline = ({
  children,
  className,
  Component,
  rightEl,
  WrapperComponent,
  ...rest
}) => (
  <WrapperComponent
    className={classnames(cls.wrapper, className)}
    {...rest}
    style={{ display: 'flex', justifyContent: 'space-between' }}
  >
    <Component className={cls.headline}>{children}</Component>
    {rightEl}
  </WrapperComponent>
)

UikHeadline.defaultProps = {
  className: null,
  Component: 'h2',
  rightEl: null,
  WrapperComponent: 'div',
}

export default UikHeadline

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".O6bxi7yI6DM3oeiIoQzI{width:360px;max-width:100%}@media only screen and (min-width: 120px)and (max-width: 320px){.O6bxi7yI6DM3oeiIoQzI{width:270px;max-width:100%}}@media only screen and (min-width: 321px)and (max-width: 410px){.O6bxi7yI6DM3oeiIoQzI{width:315px;max-width:100%}}@media only screen and (min-width: 411px)and (max-width: 700px){.O6bxi7yI6DM3oeiIoQzI{width:360px}}", "",{"version":3,"sources":["webpack://./src/containers/LoginModal/loginModal.module.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,cAAA,CAGJ,gEACI,sBACE,WAAA,CACA,cAAA,CAAA,CAIN,gEACI,sBACE,WAAA,CACA,cAAA,CAAA,CAIN,gEACI,sBACI,WAAA,CAAA","sourcesContent":[".responsiveModal {\n    width: 360px;\n    max-width: 100%;\n}\n\n@media only screen and (min-width : 120px) and (max-width : 320px) {\n    .responsiveModal {\n      width: 270px;\n      max-width: 100%;\n    }\n  }\n\n@media only screen and (min-width : 321px) and (max-width : 410px) {\n    .responsiveModal {\n      width: 315px;\n      max-width: 100%;\n    }\n  }\n\n@media only screen and (min-width: 411px) and (max-width : 700px){\n    .responsiveModal {\n        width: 360px;\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsiveModal": "O6bxi7yI6DM3oeiIoQzI"
};
export default ___CSS_LOADER_EXPORT___;

// See it in action here: https://codesandbox.io/s/o57wn157y5

import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ForwardRef extends Component {
  // static propTypes = {
  //   children: PropTypes.func.isRequired,
  //   ComponentWrapper: PropTypes.string || PropTypes.node,
  //   refPropName: PropTypes.string,
  // }
  // static defaultProps = {
  //   ComponentWrapper: 'div',
  //   refPropName: 'parentRef',
  // };
  state = {
    ref: null,
  }

  render() {
    const { children, refPropName, ComponentWrapper, ...rest } = this.props

    return (
      <ComponentWrapper
        ref={(ref) => {
          if (!this.state.ref) {
            this.setState({
              ref,
            })
          }
          return null
        }}
        {...rest}
      >
        {children({ [refPropName]: this.state.ref })}
      </ComponentWrapper>
    )
  }
}

ForwardRef.defaultProps = {
  ComponentWrapper: 'div',
  refPropName: 'parentRef',
}

ForwardRef.displayName = 'ForwardRef'

export default ForwardRef

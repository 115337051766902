// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".u_afoPbJvh9w62hp2xNw{height:100%;overflow-y:auto}", "",{"version":3,"sources":["webpack://./src/uikit/containers/UikScrollArea/scroll.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,eAAA","sourcesContent":[".wrapper {\n  height: 100%;\n  overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "u_afoPbJvh9w62hp2xNw"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import UikLoaderDots from '../uikit/components/UikLoaderDots'

import cls from './loader.module.scss'

export default () => (
  <div
    style={{
      textAlign: 'center',
    }}
  >
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <UikLoaderDots className={cls.loader} isWhite={false} />
    <br />
    <br />
    <br />
  </div>
)

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._i2mae_UohLXvTKtFvaJ{border-radius:35px;display:block}._i2mae_UohLXvTKtFvaJ.BVQu06zl52kRQlp9YqWn{padding:30px}._i2mae_UohLXvTKtFvaJ.lRZCdOa_Dk1omaE5TUtR{margin:15px}._i2mae_UohLXvTKtFvaJ.Jy9hoPILJZyqUJdbjsAV{margin:15px 0px 15px 0px}._i2mae_UohLXvTKtFvaJ.rRX7gYLXK0Et1szULe8M{max-width:80%}._i2mae_UohLXvTKtFvaJ>*:first-child{border-top-left-radius:4px;border-top-right-radius:4px}._i2mae_UohLXvTKtFvaJ>*:last-child{border-bottom-left-radius:4px;border-bottom-right-radius:4px}", "",{"version":3,"sources":["webpack://./src/uikit/components/UikWidget/widget.module.scss"],"names":[],"mappings":"AACA,sBAGE,kBAAA,CAIA,aAAA,CACA,2CACE,YAAA,CAEF,2CACE,WAAA,CAEF,2CACE,wBAAA,CAEF,2CACE,aAAA,CAEF,oCACE,0BAAA,CACA,2BAAA,CAEF,mCACE,6BAAA,CACA,8BAAA","sourcesContent":["@import 'src/initStyles/_vars.scss';\n.wrapper {\n  // border: 1px solid $borderColor;\n  // box-shadow: $boxShadow;\n  border-radius: 35px;\n  // box-shadow: 0px 3px 6px #00000029;\n  // border-radius: 4px;\n  // background: #FFFFFF;\n  display: block;\n  &.padding {\n    padding: 30px;\n  }\n  &.margin {\n    margin: 15px;\n  }\n  &.marginVertical {\n    margin: 15px 0px 15px 0px;\n  }\n  &.maxWidth {\n    max-width: 80%;\n  }\n  & > *:first-child {\n    border-top-left-radius: 4px;\n    border-top-right-radius: 4px;\n  }\n  & > *:last-child {\n    border-bottom-left-radius: 4px;\n    border-bottom-right-radius: 4px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_i2mae_UohLXvTKtFvaJ",
	"padding": "BVQu06zl52kRQlp9YqWn",
	"margin": "lRZCdOa_Dk1omaE5TUtR",
	"marginVertical": "Jy9hoPILJZyqUJdbjsAV",
	"maxWidth": "rRX7gYLXK0Et1szULe8M"
};
export default ___CSS_LOADER_EXPORT___;

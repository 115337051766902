import * as React from 'react'
import classnames from 'classnames'

import cls from './divider.module.scss'

const UikDivider = ({ className, direction, ...rest }) => (
  <div
    className={classnames(
      {
        [cls[direction]]: direction,
      },
      className
    )}
    {...rest}
  />
)

UikDivider.defaultProps = {
  className: null,
  direction: 'horizontal',
}

export default UikDivider

import React from 'react'
import classnames from 'classnames'
// import PropTypes from 'prop-types';

import cls from './content-preview.module.scss'

class ContentPreview extends React.Component {
  // to-do
  // static propTypes = {
  //   actions: PropTypes.object,
  //   BottomComponent: PropTypes.object,
  //   className: PropTypes.string,
  //   Component: PropTypes.sbject,
  //   title: PropTypes.string,
  //   imgUrl: PropTypes.string,
  //   description: PropTypes.string,
  // }

  state = {
    title: this.props.title,
    description: this.props.description,
    imgUrl: this.props.imgUrl,
  }

  componentWillReceiveProps(props) {
    if (
      props.title !== this.props.title ||
      props.descirption !== this.props.description ||
      props.imgUrl !== this.props.imgUrl
    ) {
      this.setState({
        title: props.title,
        description: props.description,
        imgUrl: props.imgUrl,
      })
    }
  }
  render() {
    const { actions, BottomComponent, className, Component, ...rest } =
      this.props
    const { title, description, imgUrl } = this.state
    return (
      <Component className={classnames(cls.wrapper, className)} {...rest}>
        <div
          className={cls.previewImage}
          style={{
            backgroundImage: `url(https://ucarecdn.com/${imgUrl}/)`,
          }}
        />
        {actions && <div className={cls.meta}>{actions}</div>}
        <div className={cls.meta}>
          <h4 className={cls.title}>{title}</h4>
          <span className={cls.views}>{description}</span>
        </div>
        <div
          className={cls.meta}
          style={{ display: 'block', marginRight: '16px' }}
        >
          {BottomComponent}
        </div>
      </Component>
    )
  }
}

ContentPreview.defaultProps = {
  actions: null,
  BottomComponent: null,
  className: null,
  Component: 'a',
  description: null,
  title: null,
}

ContentPreview.displayName = 'ContentPreview'

export default ContentPreview

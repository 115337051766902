// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ec7NS4tdmWiOW_s55dNd{background:#eaedf3;height:38px;width:1px;margin:0 30px}", "",{"version":3,"sources":["webpack://./src/uikit/components/UikTopBarMenuDivider/nav-divider.module.scss","webpack://./src/initStyles/_vars.scss"],"names":[],"mappings":"AAEA,sBACE,kBCSY,CDRZ,WAAA,CACA,SAAA,CACA,aAAA","sourcesContent":["@import 'src/initStyles/_vars.scss';\n\n.wrapper {\n  background: $borderColor;\n  height: 38px;\n  width: 1px;\n  margin: 0 30px;\n}\n","// media\n\n// 480px --> xs\n$mediaMobile: \"screen and (max-width: 768px)\";\n$mediaTablet: \"screen and (max-width: 1024px)\";\n$mediaDesktopSmall: \"screen and (max-width: 1366px)\";\n\n$fontFamily: 'Roboto', sans-serif;\n// basic\n$bg: #FBFBFD;\n$bgAccent: rgb(245, 248, 252);\n\n$borderColor: #EAEDF3;\n$borderDefault: 1px solid $borderColor;\n$borderColorAccent: rgb(223, 231, 239);\n$borderColorDarker: #D8DCE6;\n// text\n$textMain: #3E3F42;\n$textBase: #6B6C6F;\n$textSecondary: #767676; // #9EA0A5 rgba(73, 84, 92, .85) #49545c\n$textTertiary: #9EA0A5;\n$textSecondaryClear: #9EA0A5;\n// colors\n$green: #38B249;\n$blue: #1665D8;\n$orange: #e28c42;\n$violet: #6977FF;\n$red: #E6492D;\n$success: $violet;\n$primary: $violet;\n$warning: $orange;\n$error: $red;\n// base color pairs\n$colorPairs: (green, #38B249),\n(blue, #1665D8),\n(orange, #F6AB2F),\n(violet, #6977FF),\n(red, #E6492D);\n// mixins\n@mixin generateColorVariants($class, $property, $alpha) {\n  @each $name,\n  $color in (green, #38B249),\n  (blue, #1665D8),\n  (orange, #F6AB2F),\n  (violet, #6977FF),\n  (red, #E6492D) {\n    #{$class}.#{$name} {\n      #{$property}: rgba($color, $alpha);\n    }\n  }\n}\n\n@mixin textOverflow {\n  overflow: hidden;\n  white-space: nowrap;\n}\n\n@mixin backgroundCover {\n  background-repeat: no-repeat;\n  background-position: center center;\n  background-size: cover;\n}\n\n// others\n$boxShadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Ec7NS4tdmWiOW_s55dNd"
};
export default ___CSS_LOADER_EXPORT___;

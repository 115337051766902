const USER_ITEM = 'whire-app-user-auto-key'

export const setUser = (user) => {
  window.DB.setItem(
    USER_ITEM,
    JSON.stringify({
      ...user,
    })
  )
}

export const resetUser = () => {
  window.DB.removeItem(USER_ITEM)
}

export const getUser = () => {
  const data = window.DB.getItem(USER_ITEM)
  return data ? JSON.parse(data) : null
}

import { gql } from '@apollo/client'

export const CheckUserExistenceQuery = gql`
  query checkUser($slug: String!) {
    pageBySlug(slug: $slug) {
      slug
    }
  }
`

export const CreatePageMutation = gql`
  mutation createPage(
    $title: String!
    $ownerId: String!
    $summary: String!
    $slug: String!
    $image: String
    $imageModifier: String
    $video: String
    $type: COMPANY_TYPE!
    $editorStates: [StoryCreateWithoutSectionInput!]!
    $tags: [TagWhereUniqueInput!]
    $links: [LinkCreateWithoutPageInput!]
    $isPublished: Boolean!
    $code_licence: String!
    $nb_slot_available: Int!
  ) {
    createCompany(
      data: {
        name: $title
        type: $type
        owner: { connect: { id: $ownerId } }
        pages: {
          create: {
            title: $title
            summary: $summary
            slug: $slug
            image: $image
            imageModifier: $imageModifier
            video: $video
            isPublished: $isPublished
            admins: { connect: { id: $ownerId } }
            tags: { connect: $tags }
            links: { create: $links }
            sections: {
              create: [
                {
                  name: ""
                  description: ""
                  storiesTypeExpected: INTRODUCTION
                  stories: { create: $editorStates }
                }
              ]
            }
            licences: {
              create: [
                {
                  code_licence: $code_licence
                  nb_slot_available: $nb_slot_available
                }
              ]
            }
          }
        }
      }
    ) {
      id
      pages {
        id
        slug
      }
    }
  }
`
export const PageFirstQuery = gql`
  query pageBySlug($slug: String!) {
    pageBySlug(slug: $slug) {
      id
      company {
        id
        name
        owner {
          id
          plan
          referents {
            name
            id
          }
          email
          profileRequests(orderBy: { createdAt: desc }) {
            id
            status
            profileIsComplete
            createdAt
            profile {
              slug
              title
              resume
              notificationNumbers {
                id
                number
              }
              id
              owner {
                email
              }
              memberships {
                id
                trainee {
                  id
                  name
                  page {
                    id
                    slug
                  }
                  description
                }
              }
            }
            search
            center {
              name
            }
            referent {
              name
              id
            }
          }
        }
      }
      slug
      image
      imageModifier
      video
      summary
      title
      isPublished
      tags {
        id
        name
        slug
      }
      links {
        id
        url
        type
      }
      trainees (orderBy: { createdAt: asc }) {
        id
        name
        description
      }
      sections {
        id
        storiesTypeExpected
        description
        stories {
          id
          createdAt
          endDate
          endDatePresent
          startDate
          isPublished
          editorState
          title
          images
          iFrames
          videoUrl
          storyType
          tags {
            id
            name
            slug
          }
        }
      }
      profileRequests(orderBy: { createdAt: desc }) {
        id
        status
        profileIsComplete
        createdAt
        profile {
          slug
          title
          resume
          notificationNumbers {
            id
            number
          }
          id
          owner {
            email
          }
          memberships {
            id
            trainee {
              id
              name
              page {
                id
                slug
              }
              description
            }
          }
        }
        search
        center {
          name
        }
        referent {
          name
        }
      }
      notifications(orderBy: { createdAt: desc }) {
        slug
        id
        viewer
        image
        imageModifier
        createdAt
        notificationMessage
      }
      notificationNumbers {
        id
        number
      }
      licences {
        code_licence
        nb_slot_available
      }
    }
  }
`

export const UpdatePageMutation = gql`
  mutation updatePage(
    $id: String!
    $title: String!
    $summary: String!
    $image: String
    $imageModifier: String
    $video: String
    $slug: String!
    $sectionId: String!
    $storyIds: [StoryWhereUniqueInput!]!
    $stories: [StoryCreateWithoutSectionInput!]!
    $linkIds: [String!]
    $links: [LinkCreateWithoutPageInput!]
    $discoTags: [TagWhereUniqueInput!]
    $coTags: [TagWhereUniqueInput!]
    $isPublished: Boolean!
  ) {
    updatePage(
      where: { id: $id }
      data: {
        title: { set: $title }
        summary: { set: $summary }
        slug: { set: $slug }
        image: { set: $image }
        imageModifier: { set: $imageModifier }
        video: { set: $video }
        links: { deleteMany: { id: { in: $linkIds } }, create: $links }
        isPublished: { set: $isPublished }
        tags: { disconnect: $discoTags, connect: $coTags }
      }
    ) {
      id
      slug
      title
      video
    }
    updateSection(
      where: { id: $sectionId }
      data: { stories: { delete: $storyIds, create: $stories } }
    ) {
      id
    }
  }
`

export const DeletePageMutation = gql`
  mutation deletePage($id: String!) {
    deletePage(where: { id: $id }) {
      id
    }
  }
`
export const EMAIL_COMPANY = gql`
  query emailCompany($slug: String!) {
    page(where: { slug: { equals: $slug } }) {
      company {
        owner {
          email
        }
      }
    }
  }
`

export const UpdateProfileRequestMutation = gql`
  mutation updateProfileRequest(
    $id: String!
    $profileIsComplete: Boolean
    $search: SEARCH_STATUS
    $referentId: [ReferentWhereUniqueInput!]
    $discoReferentId: [ReferentWhereUniqueInput!]
    $status: REQUEST_STATUS!
  ) {
    updateProfileRequest(
      where: { id: $id }
      data: {
        profileIsComplete: { set: $profileIsComplete }
        search: { set: $search }
        status: { set: $status }
        referent: { connect: $referentId, disconnect: $discoReferentId }
      }
    ) {
      id
      profileIsComplete
      search
      status
      profile {
        slug
      }
      referent {
        name
      }
    }
  }
`

export const DeleteProfileRequestMutation = gql`
  mutation deleteProfileRequest($id: String!) {
    deleteProfileRequest(where: { id: $id }) {
      id
    }
  }
`

export const CreateNotificationMutation = gql`
  mutation createNotification(
    $viewer: String!
    $slug: String
    $image: String
    $imageModifier: String
    $pageSlug: String
    $notificationMessage: String
  ) {
    createNotification(
      data: {
        viewer: $viewer
        slug: $slug
        image: $image
        imageModifier: $imageModifier
        page: { connect: { slug: $pageSlug } }
        notificationMessage: $notificationMessage
      }
    ) {
      id
      slug
      image
      imageModifier
      notificationMessage
      page {
        slug
      }
    }
  }
`

export const CreateNotificationNumberMutation = gql`
  mutation createNotificationNumber($number: Int, $pageSlug: String) {
    createNotificationNumber(
      data: { number: $number, page: { connect: { slug: $pageSlug } } }
    ) {
      id
      number
      page {
        slug
      }
    }
  }
`

export const UpdateNotificationNumber = gql`
  mutation updateNotificationNumber($id: String!, $number: Int) {
    updateNotificationNumber(
      where: { id: $id }
      data: { number: { set: $number } }
    ) {
      id
      number
      page {
        slug
      }
    }
  }
`

export const CreateReferent = gql`
  mutation createReferent($name: String!, $email: String!) {
    createReferent(
      data: { name: $name, owner: { connect: { email: $email } } }
    ) {
      id
      name
    }
  }
`

export const DeleteReferent = gql`
  mutation deleteReferent($id: String!) {
    deleteReferent(where: { id: $id }) {
      id
      name
    }
  }
`

export const UpdateReferent = gql`
  mutation updateReferent($id: String!, $name: String!) {
    updateReferent(where: { id: $id }, data: { name: { set: $name } }) {
      id
      name
    }
  }
`

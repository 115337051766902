import * as React from 'react'
import classnames from 'classnames'

import cls from './container-v.module.scss'

const UikContainerVertical = ({ className, children, Component, ...rest }) => (
  <Component className={classnames(cls.container, className)} {...rest}>
    {children}
  </Component>
)

UikContainerVertical.defaultProps = {
  className: null,
  Component: 'div',
  children: null,
}

export default UikContainerVertical

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BEs7oyQvRqvwp4ndhIZ2{display:flex;align-items:center}", "",{"version":3,"sources":["webpack://./src/uikit/components/UikTopBarSection/top-bar-section.module.scss"],"names":[],"mappings":"AACA,sBACE,YAAA,CACA,kBAAA","sourcesContent":["@import 'src/initStyles/_vars.scss';\n.wrapper {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "BEs7oyQvRqvwp4ndhIZ2"
};
export default ___CSS_LOADER_EXPORT___;

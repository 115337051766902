import React, { node } from 'react'

// console.log(contentState.getEntity(entityKey).getData());
const DraftLink = ({ decoratedText, children }) => (
  <a
    className="Link"
    href={decoratedText}
    rel="noopener noreferrer"
    style={{ color: '#458' }}
    target="_blank"
    title={`link to ${decoratedText}`}
  >
    {children}
  </a>
)

export default DraftLink

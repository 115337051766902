import React, { useState, useRef, useEffect } from 'react';

const UikSvg = ({ alt, height, src, width, tooltipContent, ...rest }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  const handleClick = () => {
    setShowTooltip((prevShowTooltip) => !prevShowTooltip);
  };

  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    if (showTooltip) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showTooltip]);

  return (
    <div ref={tooltipRef} style={{ position: 'relative', display: 'inline-block' }} onClick={handleClick}>
      <img alt={alt} height={height} loading="lazy" width={width} src={src} {...rest} />
      {showTooltip && tooltipContent && (
        <div
          style={{
            position: 'absolute',
            bottom: '100%', // Positionner au-dessus de l'icône
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: 'white',
            color: 'white',
            padding: '0.625em', // Équivaut à environ 10px si la taille de la police est 16px
            borderRadius: '0.25em', // Équivaut à environ 4px
            border: "1px solid black",
            whiteSpace: 'normal',
            zIndex: 1000,
            fontSize: '0.75em', // Ajuster la taille de la police du tooltip
            marginBottom: '0.5em', // Équivaut à environ 8px
            //maxWidth: '25em', // Largeur maximale du tooltip (environ 400px si la taille de la police est 16px)
            width: '28em', // Largeur fixe pour le tooltip (environ 300px)
          }}
        >
          {tooltipContent}
        </div>
      )}
    </div>
  );
};

UikSvg.defaultProps = {
  height: '16px',
  width: '16px',
  tooltipContent: null, // Pas de tooltip par défaut
};

export default UikSvg;

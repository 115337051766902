// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WbXuwMfI2TJqHBueA0lC:hover{background-color:#5293c7;color:#fff;border:none}", "",{"version":3,"sources":["webpack://./src/components/ChromeHeader/chromeHeader.module.scss"],"names":[],"mappings":"AAAA,4BACE,wBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".buttonBasic:hover{\n  background-color: #5293C7;\n  color: white;\n  border: none\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonBasic": "WbXuwMfI2TJqHBueA0lC"
};
export default ___CSS_LOADER_EXPORT___;

import * as React from 'react'
import classnames from 'classnames'

import cls from './input.module.scss'

class Input extends React.PureComponent {
  static defaultProps = {
    wrapperProps: {},
    label: null,
    name: null,
    className: null,
    icon: null,
    clear: false,
    iconPosition: 'left',
    errorMessage: null,
  }

  setRef = (node) => {
    this.input = node
  }

  setValue = (value) => {
    this.input.value = value
  }

  render() {
    const {
      className,
      wrapperProps,
      name,
      icon,
      eye,
      label,
      focus,
      clear,
      iconPosition,
      errorMessage,
      onEyeClick,
      // size
      ...rest
    } = this.props

    return (
      <div
        {...wrapperProps}
        className={classnames(wrapperProps.className, {
          [cls.clear]: clear,
        })}
      >
        {label && (
          <label className={cls.inputLabel} htmlFor={name}>
            {label}
          </label>
        )}
        <div className={classnames(cls.inputWrapper)}>
          {icon && (
            <span
              className={classnames(cls.iconWrapper, {
                [cls.iconWrapperRight]: iconPosition === 'right',
              })}
            >
              {icon}
            </span>
          )}
          {eye && (
            <img
              className={classnames(cls.eye)}
              src={eye}
              onClick={onEyeClick}
            />
          )}
          <input
            ref={this.setRef}
            className={classnames(className, cls.input, {
              [cls.errorHighlight]: errorMessage,
            })}
            name={name}
            type="text"
            {...rest}
          />
        </div>
        {errorMessage && (
          <p className={cls.errorMessage} id={`error_${rest.id}`}>
            {errorMessage}
          </p>
        )}
      </div>
    )
  }
}

export default Input

import React, { useContext, useRef, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { shape, func } from 'prop-types'

import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'recompose'

import Moment from 'react-moment'

import ForwardState from 'uikit/primitives/ForwardState'

import { Menu, Item, Separator } from '@zendeskgarden/react-menus'
import { Button } from '@zendeskgarden/react-buttons'
import UIAvatar from 'react-ui-avatars'

import ChromeHeader from 'components/ChromeHeader'

import LoginModal from 'containers/LoginModal'
import Loading from 'components/Loading'

import { Link } from 'react-router-dom'

import { PublicProfileFirstQuery, UpdateNotificationNumber } from 'gql/profile'
import { PageFirstQuery } from 'gql/page'

import { UikButton, UikAvatar, UikSvg } from 'uikit/components'

import { SVG_URI } from 'utils/vars'

import { getUser } from 'utils/handleUser'
import { getMainProfile } from 'utils/handleMainProfile'

import { StoreContext } from 'contexts/Store'

import * as WORDING from 'utils/wording'
import * as ROUTES from 'utils/routes'

import '@zendeskgarden/react-chrome/dist/styles.css'
import '@zendeskgarden/react-menus/dist/styles.css'
//import '@zendeskgarden/react-buttons/dist/styles.css'

import cls from './mainHeader.module.scss'

import { NavLanding } from './NavLanding'

const retrieveMenuItems = (
  mainProfile,
  editablePage,
  ownPage,
  profile,
  creatablePage,
  traineesPage
) => {
  const user = getUser()
  const userType = !!user
    ? user.origin
    : window.location.pathname.includes('/org')
    ? 'TRAINING'
    : 'CANDIDATE'
  const augmentedData = [
    mainProfile ? (
      <Item key="go-to-main">
        <span role="img" aria-label="go to">
          👉
        </span>{' '}
        @{mainProfile.slug}
      </Item>
    ) : (
      <Item key="">
        <span role="img" aria-label="go to">
          🚫
        </span>{' '}
        {WORDING.MENU_FORBIDDEN(userType)}
      </Item>
    ),
    // editablePage && ownPage ?
    !profile && !creatablePage && userType !== 'TRAINING' ? (
      <Item key="new-story" disabled={mainProfile === null}>
        Nouvelle Story
      </Item>
    ) : null,
    userType === 'TRAINING' && !profile && !creatablePage ? (
      <li
        key="new-trainee"
        className={cls.liItem}
        disabled={mainProfile === null}
        onClick={() =>
          (window.location.href = `/org/@${mainProfile.slug}/creer-ma-section`)
        }
      >
        Nouveau groupe
      </li>
    ) : null,
    userType === 'TRAINING' && !profile && !creatablePage ? (
      <Item key="my-dashboard" disabled={mainProfile === null}>
        Tableau de Bord
      </Item>
    ) : null,
    // userType === 'CANDIDATE' && ownPage && !creatablePage ? (
    //   <Item key="edit-password" disabled={mainProfile === null}>
    //     Modifier mon mot de passe
    //   </Item>
    // ) : null,
    // userType === 'CANDIDATE' && ownPage && !creatablePage ? (
    //   <Item key="edit-mail" disabled={mainProfile === null}>
    //     Modifier mon adresse mail
    //   </Item>
    // ) : null,
    userType === 'CANDIDATE' && ownPage && editablePage ? (
      <Item key="edit-profile" disabled={mainProfile === null}>
        Modifier ma page
      </Item>
    ) : null,
    userType === 'TRAINING' && ownPage && editablePage ? (
      <Item key="edit-page" disabled={mainProfile === null}>
        Modifier ma page
      </Item>
    ) : null,
    userType === 'CANDIDATE' && ownPage && !creatablePage && /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
      navigator.userAgent
    ) === false ? (
      <Item key="cards" disabled={mainProfile === null}>
        Créer ma carte
      </Item>
    ) : null,
    userType === 'CANDIDATE' && ownPage && !creatablePage ? (
      <Item key="cover-letter" disabled={mainProfile === null}>
        Générer ma lettre de motivation
        <UikSvg
          alt="success icon"
          src={`${SVG_URI}/beta.svg`}
          style={{marginLeft: "0.4em",width: "2.8em", height: "2.8em", verticalAlign: "middle"}}
        />
      </Item>
    ) : null,
    userType === 'CANDIDATE' && ownPage && !creatablePage ? (
      <Item key="pitch" disabled={mainProfile === null}>
        Générer mon pitch d'entretien
        <UikSvg
          alt="success icon"
          src={`${SVG_URI}/beta.svg`}
          style={{marginLeft: "0.4em",width: "2.8em", height: "2.8em", verticalAlign: "middle"}}
        />
      </Item>
    ) : null,
    userType === 'CANDIDATE' && ownPage && !creatablePage ? (
      <Item key="settings" disabled={mainProfile === null}>
        Paramètres
      </Item>
    ) : null,
    ownPage && traineesPage ? (
      <Item key="edit-trainee" disabled={mainProfile === null}>
        Modifier le groupe
      </Item>
    ) : null,
    !profile && !creatablePage ? (
      <Separator key="unique-key-separator-0" />
    ) : null,
    userType === 'CANDIDATE' && ownPage && !creatablePage ? (
      <Item key="feedback" disabled={mainProfile === null}>
        Feedback
      </Item>
    ) : null,
  ]

  return [
    ...augmentedData,
    !profile && !creatablePage ? (
      <Item key="change-profile">
        {WORDING.MENU_CHANGERDEPROFILE(userType)}
      </Item>
    ) : null,
    <Separator key="unique-key-separator-1" />,

    <Item key="logout">Déconnexion</Item>,
  ]
}

const MainHeader = (props) => {
  // const userType = (!!user) ? user.origin : window.location.pathname.includes('/org') ? 'TRAINING' : 'CANDIDATE';
  const navigate = useNavigate();
 
  const [changeNotificationNumber, setChangeNotificationNumber] =
    useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const {
    me: { me },
    editablePage: { editablePage },
    ownPage: { ownPage },
    profile: { profile },
    creatablePage: { creatablePage },
    traineesPage: { traineesPage },
    normalWidth: { normalWidth, setNormalWidth },
    navigationIsOpen: { navigationIsOpen, setNavigationIsOpen },
  } = useContext(StoreContext)

  const ref = useRef(null)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenNotification(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  const profiles =
    !!me && !!me.profiles.length
      ? me.profiles.map((profile) => ({
          key: profile.slug,
          text: `- @${profile.slug}`,
        }))
      : !!me && !!me.pages.length
      ? me.pages.map((page) => ({
          key: page.slug,
          text: `- @${page.slug}`,
        }))
      : []

  const mainProfile = getMainProfile()

  const notificationToZero = () => {
    if (
      props.profileBySlug.profileBySlug &&
      props.profileBySlug.profileBySlug.notificationNumbers[0] &&
      props.profileBySlug.profileBySlug.notificationNumbers[0].number > 0
    ) {
      props
        .updateNotificationNumber({
          variables: {
            id: props.profileBySlug.profileBySlug .notificationNumbers[0].id,
            number: 0,
          },
        })
        .then((res) => {
          console.log(res, 'update notification number success')
        })
        .catch((err) => {
          console.log(err, 'update update notification number error')
        })
    } else if (
      props.pageBySlug.pageBySlug &&
      props.pageBySlug.pageBySlug.notificationNumbers[0] &&
      props.pageBySlug.pageBySlug.notificationNumbers[0].number > 0
    ) {
      props
        .updateNotificationNumber({
          variables: {
            id: props.pageBySlug.pageBySlug.notificationNumbers[0].id,
            number: 0,
          },
        })
        .then((res) => {
          console.log(res, 'update notification number success')
        })
        .catch((err) => {
          console.log(err, 'update update notification number error')
        })
    }
    setOpenNotification((prevState) => !prevState)
  }

  const getRandomColor = (letter) => {
    let colorValues = '#FFC117'
    if (letter.match(/^[a-cA-C 0-9\.\,\+\-]*$/)) {
      return colorValues
    } else if (letter.match(/^[d-fD-F\.\,\+\-]*$/)) {
      return (colorValues = '#007ED9')
    } else if (letter.match(/^[g-iG-I\.\,\+\-]*$/)) {
      return (colorValues = '#ED4A5E')
    } else if (letter.match(/^[j-lJ-L\.\,\+\-]*$/)) {
      return (colorValues = '#00A2AE')
    } else if (letter.match(/^[m-oM-O\.\,\+\-]*$/)) {
      return (colorValues = '#3C26BB')
    } else if (letter.match(/^[p-rP-R\.\,\+\-]*$/)) {
      return (colorValues = '#666be2')
    } else if (letter.match(/^[s-uS-U\.\,\+\-]*$/)) {
      return (colorValues = '#ff015b')
    } else if (letter.match(/^[v-xV-X\.\,\+\-]*$/)) {
      return (colorValues = '#30ca93')
    } else if (letter.match(/^[v-xV-X\.\,\+\-]*$/)) {
      return (colorValues = '#ffd000')
    } else {
      return (colorValues = '#F56A00')
    }
  }

  const date = new Date()

  // This should be reworked 100%
  const connectedItems = props.me
    ? [
        !mainProfile
          ? {
              text: WORDING.HEADER_MESPROFILES(!!me && me.origin),
              onClick: () => {
                window.scroll(0, 0)
                navigate(ROUTES.PROFILES(!!me && me.origin))
              },
            }
          : {},
        {
          Component: () => (
            <div className={cls.container__notification}>
              {/* <Notification profileid={props.profileid} /> */}
              {mainProfile &&
              me &&
              me.profiles.length &&
              props.profileBySlug.profileBySlug ? (
                <div className={cls.wrapper__icon__number}>
                  <input
                    type="image"
                    alt="notification"
                    src={`${SVG_URI}/cloche-on.svg`}
                    onClick={() => notificationToZero()}
                  />
                  {props.profileBySlug.profileBySlug &&
                  props.profileBySlug.profileBySlug.notificationNumbers[0] &&
                  props.profileBySlug.profileBySlug.notificationNumbers[0]
                    .number > 0 ? (
                    <span className={cls.number}>
                      {
                        props.profileBySlug.profileBySlug.notificationNumbers[0]
                          .number
                      }
                    </span>
                  ) : (
                    <span />
                  )}
                  {openNotification ? (
                    <div ref={ref} className={cls.notification__list}>
                      <h2>Notifications</h2>
                      <Separator />
                      {props.profileBySlug.profileBySlug.notifications ? (
                        props.profileBySlug.profileBySlug.notifications.map(
                          (notification, index) =>
                            notification.slug ? (
                              <div className={cls.notification__list__content}>
                                <div>
                                  {notification.image ? (
                                    <UikAvatar
                                      style={{
                                        width: '54px',
                                      }}
                                      onClick={() =>
                                        window.open(
                                          `https://www.whire.me/${notification.slug}`
                                        )
                                      }
                                      imageId={notification.image}
                                      imageModifier={notification.imageModifier}
                                      size="small"
                                    />
                                  ) : (
                                    <UIAvatar
                                      background={getRandomColor(
                                        notification.viewer
                                          .charAt(0)
                                          .toLowerCase()
                                      )}
                                      onClick={() =>
                                        window.open(
                                          `https://www.whire.me/${notification.slug}`
                                        )
                                      }
                                      name={notification.viewer}
                                      color="#FFFFFF"
                                      rounded
                                      size="28px"
                                    />
                                  )}
                                </div>
                                <div>
                                  <p>
                                    <span
                                      onClick={() =>
                                        window.open(
                                          `https://www.whire.me/${notification.slug}`
                                        )
                                      }
                                    >
                                      {notification.viewer}
                                    </span>{' '}
                                    {notification.notificationMessage}
                                    <br />
                                    <Moment
                                      from={date.getTime()}
                                      local
                                      locale="fr"
                                    >
                                      {notification.createdAt}
                                    </Moment>
                                    .
                                    <Separator key="unique-key-separator-0" />
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div className={cls.notification__list__content}>
                                <div>
                                  <UIAvatar
                                    background={getRandomColor(
                                      'v'.charAt(0).toLowerCase()
                                    )}
                                    name="V"
                                    color="#FFFFFF"
                                    rounded
                                    size="28px"
                                  />
                                </div>
                                <div>
                                  <p>
                                    <span>{notification.viewer}</span>{' '}
                                    {notification.notificationMessage}
                                    <br />
                                    <Moment
                                      from={date.getTime()}
                                      local
                                      locale="fr"
                                    >
                                      {notification.createdAt}
                                    </Moment>
                                    .
                                    <Separator key="unique-key-separator-0" />
                                  </p>
                                </div>
                              </div>
                            )
                        )
                      ) : (
                        <div style={{ height: '5em' }}>
                          <p>Vous n'avez pas de notification</p>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              ) : mainProfile &&
                me &&
                me.pages.length &&
                props.pageBySlug.pageBySlug ? (
                <div className={cls.wrapper__icon__number}>
                  <input
                    type="image"
                    alt="notification"
                    src={`${SVG_URI}/cloche-on.svg`}
                    onClick={() => notificationToZero()}
                  />
                  {props.pageBySlug.pageBySlug &&
                  props.pageBySlug.pageBySlug.notificationNumbers[0] &&
                  props.pageBySlug.pageBySlug.notificationNumbers[0].number >
                    0 ? (
                    <span className={cls.number}>
                      {
                        props.pageBySlug.pageBySlug.notificationNumbers[0]
                          .number
                      }
                    </span>
                  ) : (
                    <span />
                  )}
                  {openNotification ? (
                    <div ref={ref} className={cls.notification__list}>
                      <h2>Notifications</h2>
                      <Separator />
                      {props.pageBySlug.pageBySlug.notifications ? (
                        props.pageBySlug.pageBySlug.notifications.map(
                          (notification, index) =>
                            notification.slug ? (
                              <div className={cls.notification__list__content}>
                                <div>
                                  {notification.image ? (
                                    <UikAvatar
                                      style={{
                                        width: '54px',
                                      }}
                                      onClick={() =>
                                        window.open(
                                          `https://www.whire.me/${notification.slug}`
                                        )
                                      }
                                      imageId={notification.image}
                                      imageModifier={notification.imageModifier}
                                      size="small"
                                    />
                                  ) : (
                                    <UIAvatar
                                      background={getRandomColor(
                                        notification.viewer
                                          .charAt(0)
                                          .toLowerCase()
                                      )}
                                      onClick={() =>
                                        window.open(
                                          `https://www.whire.me/${notification.slug}`
                                        )
                                      }
                                      name={notification.viewer}
                                      color="#FFFFFF"
                                      rounded
                                      size="28px"
                                    />
                                  )}
                                </div>
                                <div>
                                  <p>
                                    <span
                                      onClick={() =>
                                        window.open(
                                          `https://www.whire.me/${notification.slug}`
                                        )
                                      }
                                    >
                                      {notification.viewer}
                                    </span>{' '}
                                    {notification.notificationMessage}
                                    <br />
                                    <Moment
                                      from={date.getTime()}
                                      local
                                      locale="fr"
                                    >
                                      {notification.createdAt}
                                    </Moment>
                                    .
                                    <Separator key="unique-key-separator-0" />
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div className={cls.notification__list__content}>
                                <div>
                                  <UIAvatar
                                    background={getRandomColor(
                                      'v'.charAt(0).toLowerCase()
                                    )}
                                    name="V"
                                    color="#FFFFFF"
                                    rounded
                                    size="28px"
                                  />
                                </div>
                                <div>
                                  <p>
                                    <span>{notification.viewer}</span>{' '}
                                    {notification.notificationMessage}
                                    <br />
                                    <Moment
                                      from={date.getTime()}
                                      local
                                      locale="fr"
                                    >
                                      {notification.createdAt}
                                    </Moment>
                                    .
                                    <Separator key="unique-key-separator-0" />
                                  </p>
                                </div>
                              </div>
                            )
                        )
                      ) : (
                        <div style={{ height: '5em' }}>
                          <p>Vous n'avez pas de notification</p>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              ) : null}
              <Menu
                arrow
                small
                placement="bottom-end"
                onChange={(selectedKey) => {
                  if (selectedKey === 'logout') {
                    return navigate('/logout')
                  }
                  const { slug, type } = mainProfile
                  if (selectedKey === 'new-trainee') {
                    setNormalWidth(false)
                    setNavigationIsOpen(false)
                    const loc = ROUTES.NEW_TRAINEE(slug)
                    navigate(loc)
                  }
                  if (selectedKey === 'my-dashboard') {
                    setNormalWidth(false)
                    setNavigationIsOpen(false)
                    const loc = ROUTES.DASHBOARD(slug)
                    navigate(loc)
                  }
                  if (selectedKey === 'edit-password') {
                    setNormalWidth(false)
                    setNavigationIsOpen(false)
                    const loc = ROUTES.EDIT_PASSWORD(slug)
                    navigate(loc)
                  }
                  if (selectedKey === 'edit-mail') {
                    setNormalWidth(false)
                    setNavigationIsOpen(false)
                    const loc = ROUTES.EDIT_MAIL(slug)
                    navigate(loc)
                  }
                  if (selectedKey === 'cover-letter') {
                    setNormalWidth(false)
                    setNavigationIsOpen(false)
                    const loc = ROUTES.COVER_LETTER(slug)
                    navigate(loc)
                  }
                  if (selectedKey === 'pitch') {
                    setNormalWidth(false)
                    setNavigationIsOpen(false)
                    const loc = ROUTES.PITCH(slug)
                    navigate(loc)
                  }
                  if (selectedKey === 'settings') {
                    setNormalWidth(false)
                    setNavigationIsOpen(false)
                    const loc = ROUTES.SETTINGS(slug)
                    navigate(loc)
                  }
                  if (selectedKey === 'cards') {
                    setNormalWidth(false)
                    setNavigationIsOpen(false)
                    const loc = ROUTES.CREATE_CARDS(slug)
                    navigate(loc)
                  }
                  if (selectedKey === 'feedback') {
                    setNormalWidth(false)
                    setNavigationIsOpen(false)
                    const loc = ROUTES.FEEDBACK(slug)
                    navigate(loc)
                  }
                  if (selectedKey === 'change-profile') {
                    setNormalWidth(false)
                    setNavigationIsOpen(false)
                    navigate(ROUTES.PROFILES(!!me && me.origin))
                  }
                  if (selectedKey === 'new-story') {
                    const loc = ROUTES.NEW_STORY(!!me && me.origin, slug)
                    setNavigationIsOpen(false)
                    navigate(loc)
                  }
                  if (
                    selectedKey === 'edit-trainee' &&
                    traineesPage &&
                    ownPage
                  ) {
                    const loc = ROUTES.TRAINEE(!!me && me.origin, slug)
                    navigate(loc)
                  }
                  if (
                    selectedKey === 'edit-profile' &&
                    editablePage &&
                    ownPage
                  ) {
                    const loc = ROUTES.EDIT(!!me && me.origin, slug)
                    setNavigationIsOpen(false)
                    navigate(loc)
                  }
                  if (
                    selectedKey === 'edit-page' &&
                    editablePage &&
                    ownPage
                  ) {
                    const loc = ROUTES.EDIT(!!me && me.origin, slug)
                    setNavigationIsOpen(false)
                    navigate(`/org/${loc}`)
                  }
                  if (selectedKey === 'go-to-main') {
                    const loc = ROUTES.SLUG(me.origin, slug)
                    navigate(loc)
                  }
                  return null
                }}
                trigger={({ ref, isOpen }) => (
                  <Button
                    size="small"
                    innerRef={ref}
                    active={isOpen}
                    className={cls.buttonBasic}
                    style={{
                      // marginBottom: "1.5em",
                      height: '32px',
                      padding: '0 2.25em',
                      minWidth: '8.33333em',
                      lineHeight: '30px',
                      fontSize: '12px',
                    }}
                  >
                    {WORDING.MENU_MONPROFIL(!!me && me.origin)}
                  </Button>
                )}
              >
                {retrieveMenuItems(
                  mainProfile,
                  editablePage,
                  ownPage,
                  profile,
                  creatablePage,
                  traineesPage
                )}
              </Menu>
            </div>
          ),
        },
      ]
    : null

  return (
    <ForwardState
      className="chromeHeader"
      style={{
        width:
          navigationIsOpen &&
          /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
            navigator.userAgent
          ) === false
            ? '100%'
            : /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
                navigator.userAgent
              )
            ? '100%'
            : '100%',
      }}
    >
      {({ state, setState }) => (
        <ChromeHeader
          standalone
          icon={{
            alt: 'logo',
            width: me ? '37em' : '100%',
            height: me ? '37em' : '100%',
            src: me
              ? `${SVG_URI}/Logo-whire_32x32.svg`
              : `${SVG_URI}/whireLetter.png`,
            style: {
              borderRight: 'none',
              marginLeft: me ? '0' : '16px',
              position: me ? '' : 'absolute',
              left: me ? '-1.8em' : '0',
            },
          }}
          onIconClick={() => {
            if (me) {
              //   // Will return to /@slug or /profiles
              const mainProfile = getMainProfile()
              if (me.origin === 'TRAINING') {
                setNormalWidth(false)
                setNavigationIsOpen(false)
              }
              const loc = ROUTES.SLUG(me.origin, mainProfile.slug)
              return navigate(loc)
              // return push(getMainProfileUrl());
            }
            return navigate('/')
          }}
          items={
            connectedItems || [
              window.location.pathname === '/' ||
              window.location.pathname.includes('/@')
                ? {
                    text: '',
                    // style: { cursor: 'pointer', color: '#6977FF'},
                    // onClick: () => {
                    //   window.scroll(0, 0);
                    //   push('/org');
                    // }
                  }
                : null,
              //   {
              // text: '',
              // style: { cursor: 'pointer', color: '#6977FF'},
              // onClick: () => {
              //   window.scroll(0, 0);
              //   push('/');
              // }
              // },
              {
                Component: () => <NavLanding />,
              },
              {
                Component: () => (
                  <UikButton
                    className={cls.loginButton}
                    onClick={() => setState({ openModal: true })}
                    success
                  >
                    Se Connecter
                    {state.openModal && (
                      <LoginModal
                        closeModal={() =>
                          setState({
                            openModal: false,
                          })
                        }
                      />
                    )}
                  </UikButton>
                ),
              },
              window.location.pathname.startsWith('/candidat')
                ? {
                    Component: () => (
                      <UikButton
                        className={cls.registerButton}
                        Component={Link}
                        to="/inscription"
                        success
                      >
                        Créer ma page
                      </UikButton>
                    ),
                  }
                : window.location.pathname.startsWith('/org')
                ? {
                    Component: () => (
                      <UikButton
                        className={cls.registerButton}
                        Component={Link}
                        to="/demo"
                        success
                      >
                        Demander une démo
                      </UikButton>
                    ),
                  }
                : window.location.pathname.startsWith('/entreprise')
                ? {
                    Component: () => (
                      <UikButton
                        className={cls.registerButton}
                        Component={Link}
                        to="/entreprise/rejoindreleclub"
                        success
                      >
                        Rejoindre le club
                      </UikButton>
                    ),
                  }
                : {
                    Component: () => (
                      <UikButton
                        className={cls.registerButton}
                        Component={Link}
                        to="/inscription"
                        success
                      >
                        S'inscrire
                      </UikButton>
                    ),
                  },
              // (window.location.pathname === '/org') ? null :
              // //{
              // //   Component: () => (
              // //     <UikButton
              // //       className={cls.registerButton}
              // //       Component={Link} to="/inscription"
              // //       success
              // //       xs
              // //     >
              // //       Créer ma page candidat
              // //     </UikButton>
              // //   ),
              //     //},
              //     // {
              //     //     Component: () => (
              //     //         <UikButton
              //     //             className={cls.loginButton}
              //     //             onClick={() => setState({openModal: true})}
              //     //             success
              //     //             xs
              //     //         >
              //     //             Connexion
              //     //             {state.openModal && (
              //     //                 <LoginModal closeModal={() => setState({openModal: false})}/>
              //     //             )}
              //     //         </UikButton>
              //     //     ),
              //     // }
            ]
          }
          style={{
            background: 'rgba(255, 255, 255, .9)',
            border: 'none',
            position:
              location.pathname === '/' ||
              location.pathname === '/candidat' ||
              location.pathname === '/candidat/creermapage' ||
              location.pathname === '/candidat/whirrior' ||
              location.pathname === '/candidat/stories' ||
              location.pathname === '/org' ||
              location.pathname === '/org/offre' ||
              location.pathname === '/org/ecosystem' ||
              location.pathname === '/org/temoignage' ||
              location.pathname === '/entreprise' ||
              location.pathname === '/entreprise/ecosystem' ||
              location.pathname === '/entreprise/club' ||
              location.pathname === '/demo' ||
              location.pathname === '/entreprise/rejoindreleclub' ||
              location.pathname === '/academy'
                ? 'fixed'
                : '',
          }}
        />
      )}
    </ForwardState>
  )
}


export default compose(
  graphql(PublicProfileFirstQuery, {
    options: (props) => ({
      variables: {
        slug:
          getMainProfile() &&
          window.location.pathname.includes(getMainProfile().slug) &&
          getMainProfile().type !== 'TRAINING'
            ? getMainProfile().slug
            : null,
      },
    }),
    name: 'profileBySlug',
  }),
  graphql(PageFirstQuery, {
    options: (props) => ({
      variables: {
        slug:
          getMainProfile() &&
          window.location.pathname.includes(getMainProfile().slug) &&
          getMainProfile().type !== 'CANDIDATE'
            ? getMainProfile().slug
            : null,
      },
    }),
    name: 'pageBySlug',
  }),
  graphql(UpdateNotificationNumber, {
    name: 'updateNotificationNumber',
  })
)(MainHeader)

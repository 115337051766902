// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v7siZ4iNzf262GzoAjJe>*:not(:last-child){margin-bottom:16px}.v7siZ4iNzf262GzoAjJe._Xk06PF_mNsalwG7qxMM{display:flex;flex-wrap:wrap;margin-bottom:0 !important;margin-right:-16px !important}.v7siZ4iNzf262GzoAjJe._Xk06PF_mNsalwG7qxMM>*{margin-right:16px;min-width:100px;margin-bottom:16px;flex-shrink:1;flex-grow:1}", "",{"version":3,"sources":["webpack://./src/uikit/components/UikFormInputGroup/form-input-group.module.scss"],"names":[],"mappings":"AACE,yCACE,kBAAA,CAEF,2CACE,YAAA,CACA,cAAA,CAQA,0BAAA,CACA,6BAAA,CARA,6CACE,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,aAAA,CACA,WAAA","sourcesContent":[".wrapper {\n  &>*:not(:last-child) {\n    margin-bottom: 16px;\n  }\n  &.horizontal {\n    display: flex;\n    flex-wrap: wrap;\n    &>* {\n      margin-right: 16px;\n      min-width: 100px;\n      margin-bottom: 16px;\n      flex-shrink: 1;\n      flex-grow: 1;\n    }\n    margin-bottom: 0 !important;\n    margin-right: -16px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "v7siZ4iNzf262GzoAjJe",
	"horizontal": "_Xk06PF_mNsalwG7qxMM"
};
export default ___CSS_LOADER_EXPORT___;

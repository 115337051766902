import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import UIAvatar from 'react-ui-avatars'
import { SVG_URI } from 'utils/vars'
import { UikTooltip } from 'uikit/components'

import { StoreContext } from 'contexts/Store'
import { getMainProfile, getMainProfileUrl } from 'utils/handleMainProfile'

import cls from './home.module.scss'

const LateralMenu = () => {
  const {
    me: { me },
    ownPage: { ownPage },
    emberViewIsOpen: { emberViewIsOpen, setEmberViewIsOpen },
  } = useContext(StoreContext)
  const [openDashboardView, setOpenDashboardView] = useState(false)
  const [openPageOptionsView, setOpenPageOptionsView] = useState(false)
  const [openHomeView, setOpenHomeView] = useState(false)
  const [openRessourcesView, setopenRessourcesView] = useState(false)
  const [openParametersView, setOpenParametersView] = useState(false)
  const [editPage, setEditPage] = useState(false)

  //only on the first render
  useEffect(() => {
    if (window.location.pathname.includes('dashboard'))
      setOpenDashboardView(true) || setEmberViewIsOpen(true)
  }, [])

  const slug = ownPage && me.origin === 'TRAINING' ? getMainProfile().slug : ''
  const dashboardView = () => {
    setEmberViewIsOpen(true)
    setOpenDashboardView(true)
    setOpenPageOptionsView(false)
    setOpenHomeView(false)
    setOpenParametersView(false)
    setopenRessourcesView(false)
  }
  const pageOptionView = () => {
    setEmberViewIsOpen(true)
    setOpenPageOptionsView(true)
    setOpenDashboardView(false)
    setOpenHomeView(false)
    setOpenParametersView(false)
    setopenRessourcesView(false)
  }
  const homeView = () => {
    setEmberViewIsOpen(false)
    setOpenHomeView(true)
    setOpenPageOptionsView(false)
    setOpenDashboardView(false)
    setOpenParametersView(false)
    setopenRessourcesView(false)
  }

  const parametersView = () => {
    setEmberViewIsOpen(true)
    setOpenHomeView(false)
    setOpenPageOptionsView(false)
    setOpenDashboardView(false)
    setOpenParametersView(true)
    setopenRessourcesView(false)
  }

  const ressourcesView = () => {
    setEmberViewIsOpen(true)
    setopenRessourcesView(true)
    setOpenHomeView(false)
    setOpenPageOptionsView(false)
    setOpenDashboardView(false)
    setOpenParametersView(false)
  }

  return (
    <div>
      {ownPage && me.origin === 'TRAINING' ? (
        <div className={cls.fixedMenu}>
          <div className={cls.menuIcon}>
            <Link to={`/org/@${slug}`}>
              <input
                type="image"
                alt="logo"
                style={{
                  width: '30px',
                  marginTop: '0.5em',
                  marginBottom: '1.5em',
                  marginRight: '0,5em',
                }}
                onClick={homeView}
                src={`${SVG_URI}/Logo-whire_32x32.svg`}
              />
            </Link>
            <UikTooltip content="Accueil">
              <Link to={`/org/@${slug}/home`}>
                <input
                  type="image"
                  alt="logo"
                  style={{ width: '25px' }}
                  onClick={homeView}
                  src={
                    openHomeView === true
                      ? `${SVG_URI}/1.home-violet.svg`
                      : `${SVG_URI}/1.home-gris.svg`
                  }
                />
              </Link>
            </UikTooltip>
            <UikTooltip content="Notre page Whire">
              <div className={openPageOptionsView === true ? cls.iconDiv : ''}>
                <input
                  type="image"
                  alt="logo"
                  style={{ width: '25px' }}
                  className={cls.iconInput}
                  onClick={pageOptionView}
                  src={
                    openPageOptionsView === true
                      ? `${SVG_URI}/2.ma-page-violet.svg`
                      : `${SVG_URI}/2.ma-page-gris.svg`
                  }
                />
              </div>
            </UikTooltip>
            <UikTooltip content="Tableau de bord">
              <div
                className={
                  openDashboardView === true ? cls.iconDiv : cls.iconDivClassic
                }
              >
                <input
                  type="image"
                  alt="logo"
                  onMouseOver="bigImg(this)"
                  style={{ width: '25px' }}
                  onClick={dashboardView}
                  src={
                    openDashboardView === true
                      ? `${SVG_URI}/3.tabl-de-board-violet.svg`
                      : `${SVG_URI}/3.tabl-de-board-gris.svg`
                  }
                />
              </div>
            </UikTooltip>
            <UikTooltip content="Ressources">
              <div
                className={
                  openRessourcesView === true ? cls.iconDiv : cls.iconDivClassic
                }
              >
                <input
                  type="image"
                  alt="logo"
                  style={{ width: '25px' }}
                  onClick={ressourcesView}
                  src={
                    openRessourcesView === true
                      ? `${SVG_URI}/4.ress-violet.svg`
                      : `${SVG_URI}/4ress.svg`
                  }
                />
              </div>
            </UikTooltip>
            <UikTooltip content="Paramètres">
              <div
                className={
                  openParametersView === true ? cls.iconDiv : cls.iconDivClassic
                }
              >
                <input
                  type="image"
                  alt="logo"
                  style={{ width: '25px' }}
                  onClick={parametersView}
                  src={
                    openParametersView === true
                      ? `${SVG_URI}/6.parametres-violet.svg`
                      : `${SVG_URI}/6.parametres-gris.svg`
                  }
                />
              </div>
            </UikTooltip>
          </div>
          {openDashboardView ? (
            <div className={cls.emberView}>
              {window.location.pathname.includes('dashboard') ? (
                ''
              ) : (
                <input
                  type="image"
                  alt="logo"
                  style={{ width: '10px' }}
                  src={`${SVG_URI}/x.svg`}
                  onClick={() =>
                    setEmberViewIsOpen(false) || setOpenDashboardView(false)
                  }
                />
              )}
              <div className={cls.emberView__title}>
                <h3>Tableau de bord</h3>
              </div>
              <Link to={`/org/@${slug}/dashboard`}>
                <p
                  style={{
                    color:
                      window.location.pathname === `/org/@${slug}/dashboard`
                        ? '#5A5EF7'
                        : '',
                  }}
                >
                  Demandes d'adhésion
                </p>
              </Link>
              <Link to={`/org/@${slug}/dashboard/mes-candidats`}>
                <p
                  style={{
                    color:
                      window.location.pathname ===
                      `/org/@${slug}/dashboard/mes-candidats`
                        ? '#5A5EF7'
                        : '',
                  }}
                >
                  Profils ajoutés
                </p>
              </Link>
              <Link to={`/org/@${slug}/dashboard/mes-candidats-archivés`}>
                <p
                  style={{
                    color:
                      window.location.pathname ===
                      `/org/@${slug}/dashboard/mes-candidats-archivés`
                        ? '#5A5EF7'
                        : '',
                  }}
                >
                  Profils archivés
                </p>
              </Link>
            </div>
          ) : null}
          {openPageOptionsView ? (
            <div className={cls.emberView}>
              {window.location.pathname.includes('dashboard') ? (
                ''
              ) : (
                <input
                  type="image"
                  alt="logo"
                  style={{ width: '10px' }}
                  src={`${SVG_URI}/x.svg`}
                  onClick={() =>
                    setEmberViewIsOpen(false) ||
                    setOpenDashboardView(false) ||
                    setOpenPageOptionsView(false)
                  }
                />
              )}
              <div className={cls.emberView__title}>
                <h3>Notre page Whire</h3>
              </div>
              <Link to={`/org/@${slug}`}>
                <p
                  style={{
                    color:
                      window.location.pathname === `/org/@${slug}`
                        ? '#5A5EF7'
                        : '',
                  }}
                >
                  Notre page Whire
                </p>
              </Link>
              <Link to={`/org/@${slug}/creer-ma-section`}>
                <p
                  style={{
                    color:
                      window.location.pathname ===
                      `/org/@${slug}/creer-ma-section`
                        ? '#5A5EF7'
                        : '',
                  }}
                >
                  Nouveau groupe
                </p>
              </Link>
              <div
                className={cls.editPageDivButton}
                onClick={() => setEditPage(!editPage)}
              >
                <p>Modifier</p>
                <img
                  style={{
                    width: editPage ? '1em' : '0.5em',
                  }}
                  src={
                    editPage
                      ? `${SVG_URI}/icon-horizontal-gris.svg`
                      : `${SVG_URI}/icon-gris.svg`
                  }
                />
              </div>
              {editPage ? (
                <div className={cls.editPageDiv}>
                  <Link to={`/org/@${slug}/edit`}>
                    <p
                      style={{
                        color:
                          window.location.pathname === `/org/@${slug}/edit`
                            ? '#5A5EF7'
                            : '',
                      }}
                    >
                      Modifier notre page
                    </p>
                  </Link>
                  <Link to={`/org/@${slug}/trainees`}>
                    <p
                      style={{
                        color:
                          window.location.pathname === `/org/@${slug}/trainees`
                            ? '#5A5EF7'
                            : '',
                      }}
                    >
                      Modifier nos groupes
                    </p>
                  </Link>
                </div>
              ) : null}
              <Link to={`/org/pages`}>
                <p>Changer de page</p>
              </Link>
            </div>
          ) : null}
          {openRessourcesView ? (
            <div className={cls.emberView}>
              {window.location.pathname.includes('dashboard') ? (
                ''
              ) : (
                <input
                  type="image"
                  alt="logo"
                  style={{ width: '10px' }}
                  src={`${SVG_URI}/x.svg`}
                  onClick={() =>
                    setEmberViewIsOpen(false) ||
                    setOpenDashboardView(false) ||
                    setopenRessourcesView(false)
                  }
                />
              )}
              <div className={cls.emberView__title}>
                <h3>Ressources</h3>
              </div>
              <Link to={`/org/@${slug}/ressources/guide`}>
                <p
                  style={{
                    color:
                      window.location.pathname ===
                      `/org/@${slug}/ressources/guide`
                        ? '#5A5EF7'
                        : '',
                  }}
                >
                  Guide du Whirrior
                </p>
              </Link>
              <Link to={`/org/@${slug}/ressources/whire-academy`}>
                <p
                  style={{
                    color:
                      window.location.pathname ===
                      `/org/@${slug}/ressources/whire-academy`
                        ? '#5A5EF7'
                        : '',
                  }}
                >
                  Whire Academy
                </p>
              </Link>
              <Link to={`/org/@${slug}/ressources/cards`}>
                <p
                  style={{
                    color:
                      window.location.pathname ===
                      `/org/@${slug}/ressources/cards`
                        ? '#5A5EF7'
                        : '',
                  }}
                >
                  Carte Whire
                </p>
              </Link>
            </div>
          ) : null}
          {openParametersView ? (
            <div className={cls.emberView}>
              {window.location.pathname.includes('dashboard') ? (
                ''
              ) : (
                <input
                  type="image"
                  alt="logo"
                  style={{ width: '10px' }}
                  src={`${SVG_URI}/x.svg`}
                  onClick={() =>
                    setEmberViewIsOpen(false) ||
                    setOpenDashboardView(false) ||
                    setOpenParametersView(false)
                  }
                />
              )}
              <div className={cls.emberView__title}>
                <h3>Paramètres</h3>
              </div>
              <Link to={`/org/@${slug}/edit-mail`}>
                <p
                  style={{
                    color:
                      window.location.pathname === `/org/@${slug}/edit-mail`
                        ? '#5A5EF7'
                        : '',
                  }}
                >
                  Adresse mail
                </p>
              </Link>
              <Link to={`/org/@${slug}/edit-password`}>
                <p
                  style={{
                    color:
                      window.location.pathname === `/org/@${slug}/edit-password`
                        ? '#5A5EF7'
                        : '',
                  }}
                >
                  Mot de passe
                </p>
              </Link>
              <Link to={`/org/@${slug}/referents`}>
                <p
                  style={{
                    color:
                      window.location.pathname === `/org/@${slug}/referents`
                        ? '#5A5EF7'
                        : '',
                  }}
                >
                  Les référent(e)s
                </p>
              </Link>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

export default LateralMenu

import * as React from 'react'
import Flip from 'react-flip-move'

import classnames from 'classnames'

import cls from './PageFade.module.scss'

class PageFade extends React.Component {
  render() {
    const { children, className, enterAnimation, leaveAnimation, ...rest } =
      this.props
    return (
      <Flip
        ref={(ref) => {
          this.ref = ref
          return null
        }}
        className={classnames(cls.animationWrapper, className)}
        duration={150}
        enterAnimation={enterAnimation || 'fade'}
        leaveAnimation={leaveAnimation || 'none'}
        {...rest}
      >
        {children}
      </Flip>
    )
  }
}

// const PageFade = ({
//   children,
//   className,
//   enterAnimation,
//   leaveAnimation,
//   ...rest
// }: UikPageFadeProps) => (
//   <Flip
//     className={classnames(cls.animationWrapper, className)}
//     duration={150}
//     enterAnimation={enterAnimation || 'fade'}
//     leaveAnimation={leaveAnimation || 'none'}
//     {...rest}
//   >
//     {children}
//   </Flip>
// );

PageFade.defaultProps = {
  className: null,
  enterAnimation: null,
  leaveAnimation: null,
}

export default PageFade

import React, { useContext } from 'react'
import { Mutation } from '@apollo/client/react/components'
import { PasswordReset } from 'gql/auth'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { UikInput, UikButton, UikFormInputGroup } from 'uikit/components'
import { SVG_URI } from 'utils/vars'

import { StoreContext } from '../../contexts/Store'

import cls from './reset.module.scss'

const ResetPassword = (prps) => {
  const {
    loginModal: { showNewPasswordSuccess, setShowNewPasswordSuccess },
  } = useContext(StoreContext)

  return (
    <Mutation mutation={PasswordReset}>
      {(reset, { loading, error }) => {
        const resetToken = window.location.search.split('=')[1]
        return (
          <Formik
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Cette adresse semble invalide')
                .required('Votre adresse email est requise'),
            })}
            onSubmit={(values, actions) => {
              reset({
                variables: {
                  ...values,
                  resetToken,
                },
              })
                .then(({ data: { passwordReset } }) => {
                  console.log('passwordReset', passwordReset)
                  setShowNewPasswordSuccess(true)
                  // prps.setCurrentState('login');
                })
                .catch((err) => {
                  console.log(err, error)
                  actions.setSubmitting(false)
                  setShowNewPasswordSuccess(true)
                })
            }}
            validateOnBlur
            render={(props) => (
              <div>
                {showNewPasswordSuccess ? (
                  <div>
                    {!error ? (
                      <div>
                        <img
                          alt="Email sent"
                          src={`${SVG_URI}/mail.svg`}
                          width="50%"
                        />
                        <h3>Mot de passe modifié !</h3>
                        <p>
                          Vous pouvez vous reconnecter avec votre nouveau mot de
                          passe
                        </p>
                        {/* <button
                                className="textButton"
                                onClick={() => prps.setCurrentState('login')}
                                style={{ fontSize: '1rem', marginTop: '16px' }}
                              >
                                ← retour
                            </button> */}
                      </div>
                    ) : (
                      <div>
                        <img
                          alt="Email sent"
                          src={`${SVG_URI}/sad-mail.svg`}
                          width="50%"
                        />
                        <h3>Erreur</h3>
                        <p>
                          Cette adresse email ne correspond pas ou votre requête
                          n&apos;a malheureusement pas pu aboutir. Si le
                          problème persiste, veuillez nous contacter.
                        </p>
                        <UikButton
                          onClick={() => {
                            setShowNewPasswordSuccess(false)
                          }}
                        >
                          Réessayer
                        </UikButton>
                      </div>
                    )}
                  </div>
                ) : (
                  <form onSubmit={props.handleSubmit}>
                    <UikFormInputGroup>
                      <div style={{ marginBottom: '20px' }}>
                        <UikInput
                          label="Adresse email"
                          name="email"
                          style={{ height: '46px' }}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.email}
                          errorMessage={
                            (props.submitCount || props.touched.password) &&
                            props.errors.password
                          }
                          placeholder="votre@email.com"
                        />
                      </div>
                      <div style={{ marginBottom: '20px' }}>
                        <UikInput
                          errorMessage={
                            (props.submitCount || props.touched.password) &&
                            props.errors.password
                          }
                          label="Nouveau mot de passe"
                          name="password"
                          placeholder="··········"
                          style={{
                            height: '46px',
                            marginBottom: '4px',
                          }}
                          type="password"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.password}
                        />
                      </div>
                    </UikFormInputGroup>
                    <UikButton
                      className={cls.btnAction}
                      type="submit"
                      success
                      lg
                      isLoading={props.isSubmitting}
                    >
                      Changer de mot de passe
                    </UikButton>
                  </form>
                )}
              </div>
            )}
          />
        )
      }}
    </Mutation>
  )
}

ResetPassword.displayName = 'ResetPasswordContainer'

export default ResetPassword

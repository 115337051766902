import * as React from 'react'
import classNames from 'classnames'
import cls from './checkbox.module.scss'
import Check from './check'

const UikCheckbox = ({
  label,
  className,
  description,
  hasError,
  toggle,
  radio,
  ...rest
}) => (
  <label
    className={classNames(cls.wrapper, className, {
      [cls.toggle]: toggle,
      [cls.radio]: radio,
      [cls.hasError]: hasError,
    })}
  >
    <input className={cls.checkbox} type="checkbox" {...rest} />
    <div className={cls.label}>
      {!toggle && !radio && <Check />}
      {label}
    </div>
    {description && <p className={cls.description}>{description}</p>}
  </label>
)

UikCheckbox.defaultProps = {
  label: null,
  className: null,
  description: null,
  hasError: false,
  toggle: false,
  radio: false,
}

export default UikCheckbox

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".z41m0k0OW9LdV9CI8o7a{display:flex;justify-content:space-between}.dvtwXq3KZzWbXahQAcNI{font-size:1.625rem;line-height:2.375rem;font-weight:400;max-width:542px}", "",{"version":3,"sources":["webpack://./src/uikit/components/UikHeadline/headline.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CAGF,sBACE,kBAAA,CACA,oBAAA,CACA,eAAA,CACA,eAAA","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: space-between;\n}\n\n.headline {\n  font-size: 1.625rem;\n  line-height: 2.375rem;\n  font-weight: 400;\n  max-width: 542px;\n  // padding-right: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "z41m0k0OW9LdV9CI8o7a",
	"headline": "dvtwXq3KZzWbXahQAcNI"
};
export default ___CSS_LOADER_EXPORT___;

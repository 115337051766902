// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bF31xtTYg1_CEQuHJBXp{position:relative}.bF31xtTYg1_CEQuHJBXp:hover .YlefzeSltL2EkPphYvQ6{opacity:0}.bF31xtTYg1_CEQuHJBXp:hover .UVLnAHHJ0JLp_Carna_A{opacity:1}.UVLnAHHJ0JLp_Carna_A{position:absolute;width:100%;top:0}.YlefzeSltL2EkPphYvQ6{opacity:1}", "",{"version":3,"sources":["webpack://./src/components/TextWidget/hover.module.scss"],"names":[],"mappings":"AACA,sBACE,iBAAA,CAGA,kDACE,SAAA,CAGF,kDACE,SAAA,CAGF,sBACE,iBAAA,CACA,UAAA,CACA,KAAA,CAIF,sBACE,SAAA","sourcesContent":[" \n.hover {\n  position: relative;\n  /* cursor: pointer; */\n\n  &:hover &__noHover {\n    opacity: 0;\n  }\n\n  &:hover &__hover {\n    opacity: 1;\n  }\n\n  &__hover {\n    position: absolute;\n    width: 100%;\n    top: 0;\n    /* opacity: 0; */\n  }\n\n  &__noHover {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hover": "bF31xtTYg1_CEQuHJBXp",
	"hover__noHover": "YlefzeSltL2EkPphYvQ6",
	"hover__hover": "UVLnAHHJ0JLp_Carna_A"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useContext } from 'react'
import { Mutation } from '@apollo/client/react/components'
import { TriggerPasswordResetMutation } from 'gql/auth'

import { Formik } from 'formik'
import * as Yup from 'yup'

import { StoreContext } from '../../contexts/Store'

import { SVG_URI } from 'utils/vars'

import { UikInput, UikButton, UikFormInputGroup } from 'uikit/components'

import cls from './recover.module.scss'

const RecoverPassword = (prps) => {
  const {
    loginModal: { showThanks, setShowThanks, showNoMail, setShowNoMail },
  } = useContext(StoreContext)

  return (
    <Mutation mutation={TriggerPasswordResetMutation}>
      {(trigger, { loading, error }) => {
        return (
          <Formik
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Cette adresse semble invalide')
                .required('Votre adresse email est requise'),
            })}
            onSubmit={(values, actions) => {
              trigger({
                variables: {
                  ...values,
                  email: values.email.toLowerCase(),
                },
              })
                .then(({ data: { triggerPasswordReset } }) => {
                  console.log('triggerPasswordReset', triggerPasswordReset)
                  setShowThanks(true)
                  // prps.setCurrentState('showThanks');
                })
                .catch((err) => {
                  console.log(err, 'eeer')
                  actions.setSubmitting(false)
                  setShowThanks(true)
                  setShowNoMail(true)
                  // prps.setCurrentState('showThanks');
                })
            }}
            validateOnBlur
            render={(props) => (
              <div>
                {showThanks ? (
                  <div>
                    {!showNoMail ? (
                      <div>
                        <img
                          alt="Email sent"
                          src={`${SVG_URI}/mail.svg`}
                          width="50%"
                        />
                        <h3>Email envoyé !</h3>
                        <p>
                          Vous recevrez d&apos;ici peu un email contenant un
                          lien de récupération.&nbsp;
                          <br />
                          En cas de difficultés, pensez à nous contacter à cette
                          adresse: tech@whire.me.
                          <br />
                          <span style={{color: "#E53B3A", fontWeight: "600"}}>Attention: pensez à regarder
                          vos spams/courriers indésirables, l'email a pu s'y glisser</span>
                        </p>
                      </div>
                    ) : (
                      <div>
                        <img
                          alt="Email sent"
                          src={`${SVG_URI}/sad-mail.svg`}
                          width="50%"
                        />
                        <h3>Erreur</h3>
                        <p>
                          Cette adresse email ne correspond pas ou votre requête
                          n&apos;a malheureusement pas pu aboutir. Si le
                          problème persiste, veuillez nous contacter à cette
                          adresse: tech@whire.me.
                        </p>
                        <UikButton
                          onClick={() => {
                            setShowThanks(false)
                            setShowNoMail(false)
                          }}
                        >
                          Réessayer
                        </UikButton>
                      </div>
                    )}
                  </div>
                ) : (
                  <form onSubmit={props.handleSubmit}>
                    <UikFormInputGroup>
                      <div style={{ marginBottom: '20px' }}>
                        <UikInput
                          label="Adresse email"
                          name="email"
                          style={{ height: '46px' }}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.email}
                          errorMessage={
                            props.touched.email && props.errors.email
                          }
                          placeholder="votre@email.com"
                        />
                      </div>
                    </UikFormInputGroup>
                    <UikButton
                      className={cls.btnAction}
                      type="submit"
                      success
                      lg
                      isLoading={props.isSubmitting}
                      // onClick={() => props.clickOnRecover('hello')}
                    >
                      Récupérer votre mot de passe
                    </UikButton>
                  </form>
                )}
              </div>
            )}
          />
        )
      }}
    </Mutation>
  )
}

RecoverPassword.displayName = 'RecoverPasswordContainer'

export default RecoverPassword

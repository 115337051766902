import React from 'react'

import TextWidget from 'components/TextWidget'
import UikContainerVertical from 'uikit/containers/UikContainerVertical'
import '../initStyles/App.scss'
import { FooterLanding } from '../modules/Landing/components/Footer/FooterLanding'

export const CguContent = () => (
  <div
    style={{
      marginLeft: '2em',
    }}
  >
    <h1>CONDITIONS GÉNÉRALES D'UTILISATION</h1>
    <h2>PREAMBULE</h2>
    <p>
      Avant toute utilisation du site internet édité par Whire et accessible à
      l’adresse <a href="https://www.whire.me/">www.whire.me</a> (ci-après «le
      Site»), vous vous engagez à lire et à accepter sans réserve :
    </p>
    <li>
      les mentions légales (accessibles en cliquant{' '}
      <a href="/mentions-legales">ici</a>)
    </li>
    <li>
      la politique de confidentialité des données personnelles (accessible en
      cliquant <a href="/privacy">ici</a>)
    </li>
    <li>
      la charte d’utilisation des cookies (accessible en cliquant{' '}
      <a href="/charte-cookies">ici</a>)
    </li>
    <li>les conditions générales d’abonnement (accessibles en cliquant ici)</li>
    <li>les présentes conditions générales d'utilisation.</li>
    <br></br>
    <p>
      Les présentes conditions générales d’utilisation définissent les modalités
      d’utilisation du Site.
    </p>
    <p>
      En cas de modification, les conditions générales d’utilisation seront
      mises à jour sur le Site. Vous êtes donc invité à venir les consulter
      régulièrement.{' '}
    </p>
    <p>
      Une validation des modifications des conditions générales d’utilisation
      sera demandée aux Utilisateurs (Candidats, Employeurs ou Organismes
      Intermédiaires) lors de leur connexion à leur Compte d’utilisateur.{' '}
    </p>
    <h2>Sommaire</h2>
    <br></br>
    <h3>ARTICLE 1ER – DÉFINITIONS</h3>
    <p>
      Candidat : Utilisateur personne physique à la recherche d’un emploi ou
      d’une opportunité professionnelle sur le Site. <br></br> <br></br>
      CVthèque : ensemble des Pages Candidat associées à une Page Organisme
      Intermédiaire. <br></br>
      <br></br>
      Page : espace sur le Site où chaque Utilisateur peut écrire, répondre à
      des questions, entreposer des photos ou des vidéos ou des liens
      hypertextes. Il existe une Page spécifique pour les Candidats, les
      Organismes Intermédiaires et les Employeurs. <br></br> <br></br>
      Organisme Intermédiaire : Utilisateur personne morale inscrit en tant que
      structure de formation, d’accompagnement ou d’insertion professionnelle.
      Pour être inscrits, les Organismes intermédiaires doivent avoir souscrit
      auprès de Whire un contrat d’abonnement. <br></br> <br></br>
      Employeur : Utilisateur personne morale inscrit sur le Site en vue de
      déposer des offres d’emploi ou de promouvoir ses métiers, sa structure
      dans la perspective d’un recrutement. Pour être inscrits, les Employeurs
      doivent avoir souscrit auprès de Whire un contrat d’abonnement. <br></br>{' '}
      <br></br>
      Services : ensemble des services mis à la disposition des Utilisateurs sur
      le Site. <br></br> <br></br>
      Site : site internet accessible à l’adresse{' '}
      <a href="https://www.whire.me/">www.whire.me</a>. <br></br> <br></br>
      Utilisateur : toute personne physique ou morale ayant adhéré aux présentes
      conditions générales d’utilisation et à{' '}
      <a href="/privacy">la politique de confidentialité des données</a> et
      ayant ouvert un Compte d’utilisateur en tant que Candidat, Organisme ou
      Employeur. <br></br> <br></br>
      Visiteur : tout Internaute consultant le Site et pouvant accéder à
      certaines fonctionnalités en tant que visiteur. <br></br> <br></br>
      Whire : société par actions simplifiée au capital de 1.000 € immatriculée
      sous le numéro 822 000 055 au RCS de PARIS, dont le siège social est situé
      32 rue de la Solidarité – 75019 PARIS et représentée par son Président,
      Monsieur Kayoum Saïd Abdallah Fane.{' '}
    </p>
    <h3>ARTICLE 2 – OBJET DU SITE</h3>
    <p>
      Whire développe et exploite une plateforme de présentation et de mise en
      relation entre des Candidats à la recherche d’un emploi ou d’une nouvelle
      opportunité professionnelle, des Organismes Intermédiaires et des
      Employeurs ayant des besoins en recrutement et désireux d’innover dans
      leur processus de recrutement. <br></br> <br></br>
      L’accès et l’utilisation du Site sont différenciés selon le profil de
      l’Utilisateur (Candidat, Organisme Intermédiaire et Employeur). <br></br>{' '}
      <br></br>
      Whire n’est pas une agence de recrutement ni un cabinet de conseil en
      recrutement. Le Site n’a pas vocation à organiser des rencontres physiques
      entre les Candidats, les Organismes intermédiaires et les Employeurs.{' '}
    </p>
    <h4>Article 2.1 – Pour les Candidats</h4>
    <p>En ouvrant un Compte d’utilisateur Candidat, vous pourrez : </p>
    <li>
      disposer d’un espace d’expression professionnelle innovant (« Page
      Candidat ») pour vous présenter aux Employeurs et Organismes
      Intermédiaires et le partager à l’ensemble de votre réseau
    </li>
    <li>postuler aux offres d’emploi disponibles sur le Site</li>
    <li>gérer vos candidatures</li>
    <li>recevoir des propositions d’emploi ou de contacts personnalisées</li>
    <li>
      consulter les présentations des Employeurs ou Organismes Intermédiaires{' '}
    </li>
    <li>
      bénéficier des conseils et des informations disponibles sur le Site{' '}
    </li>
    <li>demander à rejoindre la CVthèque de votre Organisme Intermédiaire </li>
    <br></br> <br></br>
    <p>Les services de Whire fournis aux Candidats sont gratuits.</p>
    <h4>Article 2.2 – Pour les Organismes Intermédiaires</h4>
    <p>
      Sous réserve d’avoir souscrit un contrat d’abonnement et en ouvrant un
      Compte d’utilisateur Organisme Intermédiaire, vous pourrez :{' '}
    </p>
    <li>
      disposer d’un espace pour présenter votre organisme aux Candidats et
      Employeurs et le partager à l’ensemble de votre réseau (« Page
      Organisme Intermédiaire »)
    </li>
    <li>consulter les Pages des Candidats et les contacter</li>
    <li>ajouter des Pages Candidats à votre CVthèque</li>
    <li>gérer votre CVthèque </li>
    <li>consulter les Pages des Employeurs et les contacter</li>
    <li>recevoir les candidatures de Candidats </li>
    <li>recevoir des propositions de partenariat de Employeurs</li>
    <br></br> <br></br>
    <p>
      Pour bénéficier des services de Whire, les Organismes Intermédiaires
      doivent être abonnés. Les conditions générales d’abonnement sont
      accessibles ici. Contactez-nous pour en savoir plus, notamment sur nos
      tarifs.{' '}
    </p>
    <h4>Article 2.3 – Pour les Employeurs</h4>
    <p>
      Sous réserve d’avoir souscrit un contrat d’abonnement et en ouvrant un
      Compte d’utilisateur Employeur, vous pourrez :
    </p>
    <li>
      disposer d’un espace pour présenter votre entreprise aux Candidats et aux
      Organismes et le partager à l’ensemble de votre réseau (« Page Employeur
      »)
    </li>
    <li>
      consulter les Pages des Candidats et des Organismes Intermédiaires et les
      contacter 
    </li>
    <li>recevoir les candidatures des Candidats</li>
    <li>
      recevoir des propositions de partenariat d’Organismes Intermédiaires
    </li>
    <br></br> <br></br>
    <p>
      Pour bénéficier des services de Whire, les Organismes Intermédiaires
      doivent être abonnés. Les conditions générales d’abonnement sont
      accessibles ici. Contactez-nous pour en savoir plus, notamment sur nos
      tarifs.{' '}
    </p>
    <h4>Article 2.4 – Pour les Visiteurs</h4>
    <p>
      Sans ouvrir de Compte d’utilisateur, vous ne pourrez pas accéder aux
      services proposés par Whire. Néanmoins, vous pouvez, sous réserve d’avoir
      adhéré aux présentes CGU :{' '}
    </p>
    <li>
      consulter des offres d’emploi et de stage publiées par les Employeurs
    </li>
    <li>
      consulter le contenu des Pages Employeurs et des Pages Organismes
      Intermédiaires
    </li>
    <li>
      consulter certaines informations publiées par Whire et rendues disponibles
      au public
    </li>
    <br></br>
    <h3>
      ARTICLE 3 – ACCESSIBILITE AU SITE ET CONDITIONS D’OUVERTURE D’UN COMPTE
      D’UTILISATEUR
    </h3>
    <p>
      Avant de vous inscrire, vous devez vous informer de la configuration
      minimale requise pour accéder au Site. L’accès au site internet{' '}
      <a href="https://www.whire.me/">www.whire.me</a> suppose :
    </p>
    <li>l’activation de Javascript</li>
    <li>l’acceptation des cookies</li>
    <li>l’acceptation de l'affichage des fenêtres pop-up</li>
    <br></br>
    <p>
      Il est, en outre, recommandé de télécharger la version la plus récente de
      votre navigateur Internet afin de profiter au mieux des Services.
      <br></br> <br></br>
      Les coûts de ces logiciels et de leur mise à jour sont à la charge
      exclusive de l’Utilisateur.
      <br></br> <br></br>
      Pour accéder aux Services et quel que soit votre profil (Candidat,
      Organisme Intermédiaire ou Employeur), vous devez créer un Compte
      d’utilisateur auquel vous pourrez vous connecter grâce à vos identifiants
      (une adresse e-mail et un mot de passe) qui vous sont strictement
      personnels et confidentiels.
      <br></br> <br></br>
      Vos identifiants sont strictement personnels et confidentiels. Vous êtes
      responsable de leur utilisation par des tiers et garantissez Whire contre
      toute demande à ce titre. En cas de perte ou de vol de ces identifiants,
      vous vous engagez à les modifier rapidement sur le Site.
      <br></br> <br></br>
      Pour ouvrir un Compte d’utilisateur, vous devez :{' '}
    </p>
    <li>
      avoir lu et accepté les présentes conditions générales d’utilisation,{' '}
      <a href="/privacy">la politique de confidentialité</a>,{' '}
      <a href="/charte-cookies">la charte d’utilisation des cookies</a> et{' '}
      <a href="/mentions-legales">les mentions légales</a>
    </li>
    <li>
      avoir rempli les champs obligatoires (marqués d’un astérisque) du
      formulaire d'inscription Candidat, Organisme Intermédiaire ou Employeur
    </li>
    <li>
      ne pas avoir eu votre Compte d’utilisateur résilié plus d’une fois pour
      manquement aux présentes conditions générales d’utilisation ou aux lois en
      vigueur.
    </li>
    <br></br>
    <p>
      Pour ouvrir un Compte d’utilisateur Candidat, vous devez être une personne
      physique âgée de plus de 16 ans.
      <br></br> <br></br>
      Pour ouvrir un Compte d’utilisateur Organisme Intermédiaire ou Employeur,
      vous devez être une personne morale, de droit public ou privé, valablement
      représentée par une personne physique.
      <br></br> <br></br>
      En cochant la case « J'ai lu et j'accepte les conditions générales
      d'utilisation » située à la fin du formulaire d'inscription, votre
      engagement devient effectif. À défaut d'un tel engagement, vous ne pourrez
      pas poursuivre votre processus d'inscription et accéder aux Services.
      <br></br> <br></br>
      Un message de confirmation d’inscription vous est alors envoyé à l’adresse
      e-mail renseignée dans le formulaire d’inscription.
    </p>
    <h3>ARTICLE 4 – DÉSACTIVATION DU COMPTE D’UTILISATEUR</h3>
    <p>
      A tout moment, vous pouvez désactiver votre Compte d’utilisateur. Vous ne
      pourrez alors plus accéder aux Services ni partager votre Page (le lien
      url vers votre Page ne sera plus valide).
      <br></br> <br></br>
      Pour réactiver votre Compte d’utilisateur, il vous suffira de vous
      reconnecter grâce à vos identifiants. En l'absence de reconnexion, votre
      Compte d’utilisateur restera désactivé.
      <br></br> <br></br>
      La désactivation ne supprime pas le Compte d’utilisateur ni ne libère
      l’Utilisateur de ses obligations.
      <br></br> <br></br>
      Par ailleurs, Whire s’autorise à désactiver les Comptes d’utilisateur
      inactifs depuis plus de 3 ans.{' '}
    </p>
    <h3>ARTICLE 5 – SUSPENSION ET CLÔTURE DU COMPTE D’UTILISATEUR</h3>
    <br></br>
    <h4>Article 5.1 – Résiliation à l’initiative de l’Utilisateur</h4>
    <p>
      A tout moment, vous pouvez résilier votre Compte d’Utilisateur en nous
      contactant par courrier électronique à l’adresse suivante :
      kayoum@whire.me.
      <br></br> <br></br>
      La résiliation demandée sera effective à l'expiration du délai d’un mois
      suivant la réception du courrier électronique. Elle aura pour effet de
      supprimer l’ensemble des données personnelles attachées à votre Compte
      d’utilisateur.{' '}
    </p>
    <h4>Article 5.2 – Suspension et résiliation à l’initiative de Whire</h4>
    <p>
      En cas de manquement aux présentes, Whire se réserve le droit de suspendre
      par voie de notification électronique votre Compte d’Utilisateur et de
      vous mettre en demeure de faire cesser le manquement constaté. Cette
      notification a un effet immédiat et bloque temporairement votre accès aux
      Services.
      <br></br> <br></br>
      En particulier, Whire peut être amenée à suspendre votre Compte
      d’utilisateur en cas de communication de données et contenus injurieux,
      diffamatoires ou qui contiendraient une incitation à la haine ou à la
      violence ou qui porteraient atteinte aux droits des tiers.
      <br></br> <br></br>A l’expiration d’un délai de dix (10) jours à compter
      de cette notification restée sans effet, Whire pourra résilier votre
      Compte d’Utilisateur et interrompre définitivement votre accès aux
      Services, sans autre formalité et sans préjudice des dommages et intérêts
      susceptibles d’être réclamés en réparation du préjudice subi.
      <br></br> <br></br>
      De même, en cas de danger pour le maintien de la sécurité du Site et
      notamment en cas de piratage ou de détection d’une faille dans la sécurité
      du Site, Whire se réserve le droit de désactiver votre Compte
      d’Utilisateur et d’interrompre votre accès au Site.
      <br></br> <br></br>
      Le Compte résilié est définitivement détruit. L’Utilisateur ne pourra plus
      accéder au Site, ni utiliser les Services.
      <br></br> <br></br>
      Le Compte étant détruit, les données renseignées par l'Utilisateur ne
      pourront plus être communiquées à ce dernier sous réserve des dispositions
      du décret n° 2011-219 du 25 février 2011 relatif à la conservation et à la
      communication des données permettant l’identification de toute personne
      ayant contribuée à la création d’un contenu en ligne.
    </p>
    <h3>ARTICLE 6 – OBLIGATIONS ET RESPONSABILITÉ DES UTILISATEURS</h3>
    <br></br>
    <h4>Article 6.1 – Obligations des Utilisateurs</h4>
    <p>En tant qu’Utilisateur, vous vous engagez : </p>
    <li>
      à communiquer des informations exactes et à les mettre à
      jour régulièrement
    </li>
    <li>à ne pas communiquer vos identifiants</li>
    <li>
      à ne publier aucun contenu qui puisse être dangereux ou de nature à porter
      atteinte au fonctionnement du Site
    </li>
    <li>
      à s’abstenir de toute utilisation à des fins commerciales ou publicitaires
      (via du spamming, notamment)
    </li>
    <li>
      à ne pas détourner les Utilisateurs vers un autre Site (notamment à l'aide
      de liens hypertextes) ou, plus généralement vers un service concurrent
    </li>
    <li>
      à utiliser nos Services de façon professionnelle, conformément aux
      présentes conditions générales d’utilisation et au droit français en
      vigueur (notamment en matière de protection de la vie privée et de
      propriété intellectuelle)
    </li>
    <br></br> <br></br>
    <p>
      Whire s’autorise à supprimer sans préavis tout contenu (texte, images,
      vidéos, liens hypertextes), diffamatoires, injurieux, incitant à la haine
      ou à la violence ou qui ne respecterait pas la législation en vigueur ou
      les présentes conditions générales d’utilisation.{' '}
    </p>
    <h5>
      Article 6.1.1 Obligations particulières pour les Employeurs et Organismes
      Intermédiaires
    </h5>
    <p>
      En tant qu’Employeur ou Organisme Intermédiaire, vous vous engagez à
      respecter les obligations légales et réglementaires en matière de
      diffusion d’offres d’emploi.
      <br></br> <br></br>
      Dans les conditions prévues à l’article 5, Whire se réserve le droit de
      suspendre à tout moment la diffusion d’une offre d’emploi non conforme aux
      prescriptions légales et réglementaires en la matière. Elle se réserve
      également le droit de refuser de mettre en ligne une offre d’emploi non
      conforme, étant précisé qu’un tel refus ne saurait ouvrir droit pour
      l’Utilisateur à une quelconque indemnité.{' '}
    </p>
    <h5>Article 6.1.2 Obligations particulières pour les Candidats</h5>
    <p>
      En tant que Candidat, vous garantissez l’authenticité des informations
      contenues sur votre Page, en matière de compétences, d’expériences ou de
      formation professionnelles.
    </p>
    <h4>Article 6.2 – Responsabilité des Utilisateurs</h4>
    <p>
      En tant qu’Utilisateur, vous êtes seul responsable de l'usage des
      informations et données que vous fournissez sur le Site, quelle qu’en soit
      la nature et l’origine (téléchargement, publication, transmission, liens
      hypertextes, mise à disposition, notamment titres, diplômes,
      qualifications, adresses, descriptions…).
      <br></br> <br></br>
      Whire n'exerce aucun contrôle des sites et sources externes (pages web,
      forums...) vers lesquels redirigent les liens hypertextes mis en ligne par
      les Utilisateurs sur le Site et ne saurait être tenu responsable de leur
      contenu.
      <br></br> <br></br>
      En cas de plainte ou de réclamation d’un tiers du fait d’une information
      ou donnée que vous avez fournie, vous devrez nous communiquer toutes
      explications et justifications et répondre, dans tous les cas, à l’égard
      de Whire et des tiers aux droits desquels vous auriez porté atteinte, des
      conséquences éventuelles de vos actes au regard des dispositions légales
      et réglementaires applicables, y compris celles emportant une
      responsabilité pénale.
      <br></br> <br></br>
      De la même manière, vous vous engagez à garantir Whire contre toute
      condamnation prononcée à son encontre trouvant son origine dans un
      manquement pouvant vous être imputé.
    </p>
    <h3>ARTICLE 7 – RESPONSABILITÉ DE WHIRE</h3>
    <p>
      Whire s'engage à mettre en œuvre tous les moyens nécessaires pour fournir
      à ses Utilisateurs un service en ligne conforme aux règles de l'art et à
      la législation.
      <br></br> <br></br>
      Whire ne saurait être tenue responsable en cas de défaillance du
      fournisseur d'accès Internet de l'Utilisateur ou en cas de non
      fonctionnement ou de mauvaises conditions d'accès provoquées par
      l'encombrement du réseau internet et toutes autres raisons extérieures à
      Whire et ses prestataires, et ayant un caractère de force majeure tel que
      défini par les juridictions françaises.
      <br></br> <br></br>
      Toutefois, en cas de suspension de l'accès aux Services due à une panne
      informatique ou en cas d'impossibilité d'utiliser les Services du fait de
      Whire, celui-ci sera prorogé de plein droit au profit de l'Utilisateur
      pour une durée équivalente à celle de la suspension des Services.
      <br></br> <br></br>
      Whire décline toute responsabilité en cas d'escroquerie, d'usurpation
      d'identité et autres infractions pénales commises par des Utilisateurs au
      moyen du Site et des Services mis à leur disposition, ou d'atteintes à
      l'image ou à la vie privée d'un tiers commises dans les mêmes conditions.
      <br></br> <br></br>
      Whire se réserve le droit de sauvegarder tout contenu litigieux, notamment
      pour le mettre à disposition des autorités compétentes.
    </p>
    <h3>ARTICLE 8 – PROPRIÉTÉ INTELLECTUELLE</h3>
    <br></br>
    <h4>Article 8.1 – Propriété intellectuelle de Whire</h4>
    <p>
      Le contenu et la structure générale du Site, les marques, les logos, les
      textes, les éléments graphiques animés ou non, les photos, les vidéos, les
      sons de même que tous les éléments téléchargeables ainsi que tout élément
      permettant de naviguer sur le site{' '}
      <a href="https://www.whire.me/">www.whire.me</a> sont la propriété
      intellectuelle exclusive de Whire.
      <br></br> <br></br>
      Sont également la propriété intellectuelle exclusive de Whire les
      programmes, logiciels, savoirs-faire et tout autre élément informatique
      composant le Site.
      <br></br> <br></br>
      Ils ne peuvent être reproduits, utilisés, traduits, adaptés ou
      représentés, diffusés sous quelque forme que ce soit, sur quelque support
      que ce soit et par quelque media que ce soit, sans l'autorisation expresse
      de Whire, sous peine de poursuites judiciaires.
      <br></br> <br></br>
      Les droits d'utilisation concédés par Whire à l'Utilisateur sont réservés
      à un usage privé et personnel dans le cadre de son inscription au Site.
      Toute autre utilisation par l'Utilisateur est interdite sans
      l'autorisation de Whire.
      <br></br> <br></br>
      L'Utilisateur s'interdit notamment de modifier, copier, reproduire,
      télécharger, diffuser, transmettre, exploiter commercialement et/ou
      distribuer de quelque façon que ce soit les Services, les pages du Site,
      ou les codes informatiques des éléments composant les Services et le Site.
    </p>
    <h4>Article 8.2 – Propriété intellectuelle de l’Utilisateur</h4>
    <p>
      En vous inscrivant aux Services de Whire, vous restez propriétaire du
      contenu et des informations (textes, images, photos, etc.) que vous
      intégrez et diffusez sur le Site et en particulier sur votre Page
      personnelle.
      <br></br> <br></br>
      Toutefois, aux termes des présentes, vous concédez à Whire un droit
      d’utilisation de l’intégralité des contenus que vous fournissez sur le
      Site, sans aucune contrepartie, notamment financière. Ce droit comprend
      celui de reproduire, repésenter, diffuser, adapter, modifier, traduire et
      communiquer ces informations et contenus aux autres Utilisateurs du Site
      et sur tout autre réseau social.
      <br></br> <br></br>
      Vous autorisez Whire à céder ce droit d’utilisation à des tiers pour la
      bonne exécution de ses Services. Ces droits sont concédés pour toute la
      durée d'exécution des présentes conditions générales d’utilisation.
      <br></br> <br></br>A ce titre, vous autorisez Whire à modifier vos
      contenus afin de respecter la charte graphique du Site, de les rendre
      compatibles avec ses performances techniques ou de permettre à Whire de
      fournir ses services.
      <br></br> <br></br>
      Vous pouvez mettre fin à ce droit d’utilisation non-exclusive en résiliant
      votre Compte d’Utilisateur dans les conditions prévues à l’article 5, sous
      réserve d’un délai raisonnable nécessaire pour l’éliminer.
    </p>
    <h3>ARTICLE 9 – MODIFICATION DES CONDITIONS GENERALES D’UTILISATION</h3>
    <p>
      Whire se réserve le droit de modifier ou de compléter les présentes
      conditions générales d’utilisation à tout moment, notamment pour s’adapter
      aux évolutions légales ou technologiques.
      <br></br> <br></br>
      En tant qu’Utilisateur, vous serez averti par courrier électronique en cas
      de modification et votre consentement sera requis pour qu’elles vous
      deviennent applicables.
      <br></br> <br></br>
      En cas de refus de ces modifications, vous ne pourrez plus accéder à votre
      Compte d’utilisateur et utiliser nos Services.{' '}
    </p>
    <h3>ARTICLE 10 – LITIGES</h3>
    <p>
      Les présentes conditions générales d’utilisation sont régies par le droit
      français.
      <br></br> <br></br>
      En cas de contestation relative aux services fournis par Whire ou de
      litige né de l’exécution ou de l’interprétation des présentes, les
      juridictions du ressort du siège social de Whire auront une compétence
      exclusive.
      <br></br> <br></br>
      Dans l’hypothèse où une ou plusieurs clauses des présentes conditions
      générales d’utilisation étai(en)t déclarée(s) nulle(s) ou inopposable(s) à
      la suite d'une décision de justice définitive, les autres clauses
      garderont toute leur force et leur portée sous réserve que les présentes
      conditions générales d’utilisation n’en soient pas dénaturées et que leur
      économie générale ne s’en trouve pas bouleversée.
      <br></br> <br></br>
      Le fait pour une Partie de ne pas se prévaloir d'un manquement de l'autre
      Partie à l'une des clauses des présentes conditions générales
      d’utilisation ne saurait s'interpréter comme une renonciation de sa part à
      se prévaloir à l'avenir d'un tel manquement.
    </p>
    <h3>ARTICLE 11 – NOUS CONTACTER</h3>
    <p>
      Pour toute question relative à nos conditions générales d’utilisation,
      vous pouvez nous contacter par e-mail (
      <a href="kayoum@whire.me">kayoum@whire.me</a>) ou par courrier (Whire SAS
      - 32, rue de la Solidarité – 75019 PARIS).{' '}
    </p>
  </div>
)

export default () => (
  <div className="App">
    <UikContainerVertical>
      <TextWidget
        content={{
          pages: 'Cgu',
        }}
      />
    </UikContainerVertical>
    <FooterLanding />
  </div>
)

import React from 'react'

import ReactPlayer from 'react-player'

import cls from './video-player.module.scss'

export default ({ url }) => (
  <div className={cls.wrapper}>
    <ReactPlayer
      className={cls.player}
      controls
      height="100%"
      url={url}
      width="100%"
    />
  </div>
)

import React from 'react'

import TextWidget from 'components/TextWidget'
import UikContainerVertical from 'uikit/containers/UikContainerVertical'
import '../initStyles/App.scss'
import { FooterLanding } from '../modules/Landing/components/Footer/FooterLanding'

export const LegalMentionContent = () => (
  <div
    style={{
      marginLeft: '2em',
      marginTop: '2em',
    }}
  >
    <h1>MENTIONS LEGALES</h1>
    <h2>Éditeur</h2>
    <p>
      Le présent site accessible à partir de l’adresse{' '}
      <a href="https://www.whire.me/">www.whire.me</a> est édité par la société
      Whire, société par actions simplifiée au capital de 1.000 € immatriculée
      sous le numéro 822 000 055 au RCS de Paris.
      <br></br> <br></br>
      Siège social : 32 rue de la Solidarité – 75019 Paris
      <br></br> <br></br>
      N° de TVA intracommunautaire : FR 23 822000055
      <br></br> <br></br>
      Téléphone : 06 68 49 26 27
      <br></br> <br></br>
      Courriel : <a href="kayoum@whire.me">kayoum@whire.me</a>{' '}
    </p>
    <h2>Directeur de la publication :</h2>
    <p>
      Kayoum Saïd Abdallah Fane, Président
      <br></br> <br></br>
      Courriel : <a href="kayoum@whire.me">kayoum@whire.me</a>{' '}
    </p>
    <h2>Hébergement :</h2>
    <p>
      Vos données sont conservées et stockées, pendant toute la durée de leur
      conservation sur les serveurs de la société Heroku, dans l'Union européenne. 
      Vos données ne feront l'objet d'aucun
      transfert en dehors de l'Union européenne dans le cadre de l'utilisation
      des services que nous vous proposons.
    </p>
  </div>
)

export default () => {
  return (
    <div className="App">
      <UikContainerVertical>
        <TextWidget
          content={{
            pages: 'LegalMention',
          }}
        />
      </UikContainerVertical>
      <FooterLanding />
    </div>
  )
}

import React from 'react'

export const PROFILES_MESPROFILS = (userType) => {
  if (userType === 'TRAINING') {
    return '👉 Mes Pages'
  } else if (userType === 'BUSINESS') {
    return '👉 Mes Pages'
  } else {
    return '👉 Mes Profils'
  }
}

export const PROFILES_HEADER = (userType) => {
  if (userType === 'TRAINING') {
    return 'Chez Whire, vous pouvez créer autant de pages que vous le souhaitez'
  } else if (userType === 'BUSINESS') {
    return 'Chez Whire, vous pouvez créer autant de pages que vous le souhaitez'
  } else {
    return 'Chez Whire, je peux créer autant de profils que je veux'
  }
}

export const PROFILES_CTA = (userType) => {
  if (userType === 'TRAINING') {
    return '👉 Nouvelle page'
  } else if (userType === 'BUSINESS') {
    return '👉 Nouvelle page'
  } else {
    return '👉 Nouveau profil'
  }
}

export const HEADER_MESPROFILES = (userType) => {
  if (userType === 'TRAINING') {
    return 'Mes pages'
  } else if (userType === 'BUSINESS') {
    return 'Mes pages'
  } else {
    return 'Mes profils'
  }
}

export const MENU_MONPROFIL = (userType) => {
  if (userType === 'TRAINING') {
    return 'Menu'
  } else if (userType === 'BUSINESS') {
    return 'Menu'
  } else {
    return 'Menu'
  }
}

export const MENU_CHANGERDEPROFILE = (userType) => {
  if (userType === 'TRAINING') {
    return 'Changer de page'
  } else if (userType === 'BUSINESS') {
    return 'Changer de page'
  } else {
    return 'Changer de profil'
  }
}

export const MENU_FORBIDDEN = (userType) => {
  if (userType === 'TRAINING') {
    return "Sélectionnez d'abord une page"
  } else if (userType === 'BUSINESS') {
    return "Sélectionnez d'abord une page"
  } else {
    return "Sélectionnez d'abord un profil"
  }
}

export const STORY_HEADER = (userType) => {
  if (userType === 'TRAINING') {
    return '👉 Montrez ce que fait votre organisation en ajoutant du contenu concret'
  } else if (userType === 'BUSINESS') {
    return '👉 Montrez ce que fait votre entreprise en ajoutant du contenu concret'
  } else {
    return '👉 Je montre mon expertise ou ma motivation en ajoutant du contenu concret'
  }
}

export const STORY_SUBTITLE = (userType) => {
  if (userType === 'CANDIDATE') {
    return (
      <div>
        <p> 3 conseils pour mes stories :</p>
        <br />
        <div>
          <p>
            ⚡️Je rassure l’employeur et je lui donne envie de me rencontrer ⚡{' '}
          </p>
          <p>"Je lui mets des paillettes dans sa vie" 🌟 </p>
          <p>
            Il a besoin d’être rassuré. Très bien, je lui donne des preuves
            concrètes de ce que je sais faire et je lui illustre ma motivation
            avec des exemples.
          </p>
          <br />
          <p>
            🔮🕵️ Je lui donne la bonne grille de lecture de mon parcours 🕵🔮
          </p>
          <p>
            Le recruteur n’est ni un bon détective, ni un voyant. Pour éviter
            qu’il passe à côté de mon histoire, c’est moi qui fais le lien entre
            ce que je lui raconte et mon projet actuel.
          </p>
          <br />
          <p> 😁 Je prends du plaisir ! 🤸‍</p>
          <p>
            Je ne veux pas que le recruteur ressemble à ça 😴 en lisant ma page.
            Place au naturel ! Je parle avec mes propres mots, sans jargon, sans
            blabla, sans langue de bois et surtout, JE PRENDS DU PLAISIR !
          </p>
        </div>
      </div>
    )
  } else {
    return ''
  }
}

export const STORY_CTA = (userType) => {
  if (userType === 'CANDIDATE') {
    return 'Raconter'
  } else {
    return 'Détailler'
  }
}

export const PROFILE_PAGE_TITLE = (userType) => {
  if (userType === 'TRAINING') {
    return '(Vous pouvez modifier le titre)'
  } else if (userType === 'BUSINESS') {
    return '(Vous pouvez modifier le titre)'
  } else {
    return '(Je peux le modifier)'
  }
}

import React, { useState } from 'react'
import cls from './tooltip.scss'

const UikTooltip = (props) => {
  let timeout
  const [active, setActive] = useState(false)

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true)
    }, props.delay || 400)
  }

  const hideTip = () => {
    clearInterval(timeout)
    setActive(false)
  }

  return (
    <div
      className={cls.tooltipWrapper}
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {props.children}
      {active && (
        <div className={cls.tooltipTip}>
          {/* Content */}
          {props.content}
        </div>
      )}
    </div>
  )
}

export default UikTooltip

/* eslint-disable */

export const extractSlug = (pathname) =>
  !!pathname.match(/(\/@[a-zA-Z0-9_.-]*)/) &&
  pathname.match(/(\/@[a-zA-Z0-9_.-]*)/)[0].substr(2)

export const traineePage = (pathname) =>
  !!pathname.match(/\/org\/@[a-zA-Z0-9_.-]*\/trainees\/([a-zA-Z0-9_.-]*)$/)
export const dashboardPage = (pathname) =>
  !!pathname.match(/\/org\/@[a-zA-Z0-9_.-]*(\/dashboard)$/)

export const proPage = (pathname) =>
  !!pathname.match(/\/org\/@[a-zA-Z0-9_.-\/]*$/)

export const userPage = (pathname) =>
  !!pathname.match(/^(\/@[a-zA-Z0-9_.-\/]*)$/)

export const editPage = (pathname) => !!pathname.match(/(\/edit)$/)

export const storyPage = (pathname) =>
  !!pathname.match(/\/@[a-zA-Z0-9_.-]*\/nouvelle\/([a-zA-Z0-9_.-]*)$/)

export const profilePage = (pathname) =>
  !!pathname.match(/(\/profiles)$/) || !!pathname.match(/(\/pages)$/)

export const createPage = (pathname) => !!pathname.match(/(\/creer-ma-page)$/)

import * as React from 'react'
import classnames from 'classnames'

import cls from '../UikInput/input.module.scss'

class TextArea extends React.PureComponent {
  static defaultProps = {
    wrapperProps: {},
    label: null,
    name: null,
    className: null,
    icon: null,
    clear: false,
    rows: 6,
  }

  setRef = (node) => {
    this.input = node
  }

  setValue = (value) => {
    this.input.value = value
  }

  render() {
    const {
      className,
      wrapperProps,
      name,
      icon,
      label,
      focus, // eslint-disable-line
      clear,
      rows,

      // size
      ...rest
    } = this.props

    return (
      <div
        {...wrapperProps}
        className={classnames(wrapperProps.className, {
          [cls.clear]: clear,
        })}
      >
        {label && (
          <label className={cls.inputLabel} htmlFor={name}>
            {label}
          </label>
        )}
        <div className={cls.inputWrapper}>
          {icon && <span className={cls.iconWrapper}>{icon}</span>}
          <textarea
            ref={this.setRef}
            className={classnames(className, cls.input)}
            id={name}
            name={name}
            rows={rows}
            style={{ lineHeight: '1.415rem', paddingTop: '10px' }}
            type="textarea"
            {...rest}
          />
        </div>
      </div>
    )
  }
}

export default TextArea

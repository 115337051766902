// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cYiz_MdPAO20rM3gJCEn{background:#fff;border-right:1px solid #eaedf3;height:100%;flex-grow:0;flex-shrink:0;flex-basis:270px;width:270px;overflow-y:auto}.cYiz_MdPAO20rM3gJCEn.yWiXVbNQvFv_LS8lDR5h{border-right:none;border-left:1px solid #eaedf3}", "",{"version":3,"sources":["webpack://./src/uikit/containers/UikNavPanel/nav-panel.module.scss"],"names":[],"mappings":"AACA,sBAEE,eAAA,CAEA,8BAAA,CACA,WAAA,CACA,WAAA,CACA,aAAA,CACA,gBAAA,CACA,WAAA,CACA,eAAA,CACA,2CACE,iBAAA,CACA,6BAAA","sourcesContent":["@import 'src/initStyles/_vars.scss';\n.wrapper {\n  /* Menu Base: */\n  background: white;\n\n  border-right: 1px solid $borderColor;\n  height: 100%;\n  flex-grow: 0;\n  flex-shrink: 0;\n  flex-basis: 270px;\n  width: 270px;\n  overflow-y: auto;\n  &.positionRight {\n    border-right: none;\n    border-left: 1px solid $borderColor;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "cYiz_MdPAO20rM3gJCEn",
	"positionRight": "yWiXVbNQvFv_LS8lDR5h"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V5ik0pbGz4qiuAL7ZbHu{position:relative;padding-top:56.25%}.gHGANzQ9vRSi3QdgAiGe{position:absolute;top:0;left:0;width:100%;height:100%}", "",{"version":3,"sources":["webpack://./src/components/VideoPlayer/video-player.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,kBAAA,CAGF,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".wrapper {\n  position: relative;\n  padding-top: 56.25%;\n}\n\n.player {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "V5ik0pbGz4qiuAL7ZbHu",
	"player": "gHGANzQ9vRSi3QdgAiGe"
};
export default ___CSS_LOADER_EXPORT___;

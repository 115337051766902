import * as React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import cls from './tagInterest.module.scss'

const UikTagInterest = ({
  children,
  className,
  color,
  large,
  name,
  Component = 'span',
  ...rest
}) => (
  <Component
    className={classnames(
      large && children ? cls.largeWrapperInterest : cls.wrapperInterest,
      className,
      {
        [color]: color,
      }
    )}
    {...rest}
  >
    {children}
  </Component>
)

UikTagInterest.propTypes = {
  className: PropTypes.string,
  Component: PropTypes.any, // eslint-disable-line
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  large: PropTypes.bool,
  name: PropTypes.string,
}

UikTagInterest.defaultProps = {
  className: null,
  Component: 'span',
  color: null,
  large: false,
  name: null,
}

export default UikTagInterest

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AtRh3mzzaC5Y4jB7gE0u{width:100%;overflow-x:scroll;overflow-y:hidden;white-space:nowrap;min-height:100px;padding:30px;padding-top:40px;padding-left:12px}", "",{"version":3,"sources":["webpack://./src/uikit/containers/UikHorizontalScrollArea/scroll-horizontal.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,iBAAA,CACA,iBAAA,CACA,kBAAA,CACA,gBAAA,CACA,YAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":[".wrapper {\n  width: 100%;\n  overflow-x: scroll;\n  overflow-y: hidden;\n  white-space: nowrap;\n  min-height: 100px;\n  padding: 30px;\n  padding-top: 40px;\n  padding-left: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AtRh3mzzaC5Y4jB7gE0u"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VrdeUN5ZTFwImCBGBlTi+.VrdeUN5ZTFwImCBGBlTi{margin-top:20px}.VrdeUN5ZTFwImCBGBlTi:last-child{margin-bottom:20px}.VrdeUN5ZTFwImCBGBlTi:first-child{margin-top:15px}", "",{"version":3,"sources":["webpack://./src/uikit/components/UikNavSection/nav-section.module.scss"],"names":[],"mappings":"AACE,4CACE,eAAA,CAEF,iCACE,kBAAA,CAEF,kCACE,eAAA","sourcesContent":[".wrapper {\n  & + & {\n    margin-top: 20px;\n  }\n  &:last-child {\n    margin-bottom: 20px;\n  }\n  &:first-child {\n    margin-top: 15px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "VrdeUN5ZTFwImCBGBlTi"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B_p3WIsmHQFf2AX8pMad{width:8em;margin:auto}", "",{"version":3,"sources":["webpack://./src/components/loader.module.scss"],"names":[],"mappings":"AAEA,sBACE,SAAA,CACA,WAAA","sourcesContent":["@import \"src/initStyles/_vars.scss\";\n\n.loader {\n  width: 8em;\n  margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "B_p3WIsmHQFf2AX8pMad"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SY_g8rGd58MelYCEC2a8{display:flex;flex-direction:column;height:100%;flex-grow:1}.SY_g8rGd58MelYCEC2a8>*{min-height:1px}", "",{"version":3,"sources":["webpack://./src/uikit/containers/UikContainerVertical/container-v.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,WAAA,CACA,wBACE,cAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  flex-grow: 1;\n  & > * {\n    min-height: 1px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SY_g8rGd58MelYCEC2a8"
};
export default ___CSS_LOADER_EXPORT___;

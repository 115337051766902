import React from 'react'
import handleLinkStrategy from './handleLinkStrategy'
import DraftLink from './DraftLink'

const styles = {
  code: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
  codeBlock: {
    marginBottom: '1.125em',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 20,
    whiteSpace: 'pre-wrap',
  },
}

const genericStyles = {
  minHeight: '21px',
  wordBreak: 'break-word',
}

const generateRandomId = () => {
  let text = ''
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < 7; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

// just a helper to add a <br /> after a block
const addBreaklines = (children) => children.map((child) => [child, <br />])

export default {
  /**
   * Those callbacks will be called recursively to render a nested structure
   */
  inline: {
    // The key passed here is just an index based on rendering order inside a block
    BOLD: (children, { key }) => (
      <strong style={{ fontWeight: '600' }} key={key}>
        {children}
      </strong>
    ),
    ITALIC: (children, { key }) => <em key={key}>{children}</em>,
    UNDERLINE: (children, { key }) => <u key={key}>{children}</u>,
    CODE: (children, { key }) => (
      <span key={key} style={styles.code}>
        {children}
      </span>
    ),
  },
  blocks: {
    unstyled: (children) =>
      children.map((child) => (
        <p
          key={generateRandomId()}
          style={{ minHeight: '21px', wordBreak: 'break-word' }}
        >
          {child}
        </p>
      )),
    blockquote: (children) => (
      <blockquote>{addBreaklines(children)}</blockquote>
    ),
    'header-one': (children) =>
      children.map((child) => <h1 style={genericStyles}>{child}</h1>),
    'header-two': (children) =>
      children.map((child) => <h2 style={genericStyles}>{child}</h2>),
    'header-three': (children) =>
      children.map((child) => <h3 style={genericStyles}>{child}</h3>),
    // You can also access the original keys of the blocks
    'code-block': (children, { keys }) => (
      <pre style={styles.codeBlock} key={keys[0]}>
        {addBreaklines(children)}
      </pre>
    ),
    // or depth for nested lists
    'unordered-list-item': (children, { depth, keys }) => (
      <ul key={keys[keys.length - 1]} className={`ul-level-${depth}`}>
        {children.map((child) => (
          <li>{child}</li>
        ))}
      </ul>
    ),
    'ordered-list-item': (children, { depth, keys }) => (
      <ol key={keys.join('|')} className={`ol-level-${depth}`}>
        {children.map((child, index) => (
          <li key={keys[index]}>{child}</li>
        ))}
      </ol>
    ),
  },
  /**
   * Entities receive children and the entity data
   */
  // entities: {
  //   // key is the entity key value from raw
  //   LINK: (children, data, { key })
  // => <Link href={data.url} key={key} to={data.url}>{children}</Link>,
  // },
  /**
   * Array of decorators,
   * Entities receive children and the entity data,
   * inspired by https://facebook.github.io/draft-js/docs/advanced-topics-decorators.html
   * it's also possible to pass a custom Decorator class that matches the [DraftDecoratorType](https://github.com/facebook/draft-js/blob/master/src/model/decorators/DraftDecoratorType.js)
   */
  decorators: [
    {
      // by default linkStrategy receives a ContentBlock stub
      // (more info under Creating the ContentBlock)
      // strategy only receives first two arguments, contentState is yet not provided
      strategy: handleLinkStrategy,
      // component - a callback as wiith other renderers
      // decoratedText a plain string matched by the strategy
      // if your decorator depends on draft-js contentState
      // you need to provide convertFromRaw in redraft options
      component: DraftLink,
    },
    // {
    //   strategy: (contentBlock, callback) => {
    //     const text = contentBlock.getText();
    //     // -> ["@brian_lovin", " @mxstbr"];
    //     const matches = text.match(/(\s|^)@[a-z0-9_-]+/gi);
    //     if (!matches || matches.length === 0) return;
    //
    //     matches.forEach((match) => {
    //       // Because JS Regexps don't have lookbehinds we have to include
    //       // the whitespace before the mention in the regex match
    //       // The .trim here makes sure we don't highlight that whitespace as a mention
    //       // -> " @mxstbr" -> "@mxstbr"
    //       const mention = match.trim();
    //       const start = text.indexOf(mention);
    //       callback(start, start + mention.length);
    //     });
    //   },
    //   component: props => <span style={{ textDecoration: 'underline' }}>{props.children}</span>,
    // }
  ],
}
